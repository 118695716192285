/* ------------------------------------------------------------ *\
	Theme
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	Colors
\* ------------------------------------------------------------ */

:root {
    --multi-background: url(/static/media/oat_bg.7a18f432.png);
    --multi-concept-color: #72980A;
    --link-color: black;
    --spinner-color: white;
}

/* ------------------------------------------------------------ *\
	Modules
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	Multi Concept
\* ------------------------------------------------------------ */

.multi-concept  { background-size: cover; display: -ms-flexbox; display: flex; -ms-flex-direction: column; flex-direction: column; -ms-flex-positive: 1; flex-grow: 1; overflow: hidden; padding-bottom: 16px; font-size: 16px; font-weight: normal; text-align: center; background: var(--multi-background);}

.multi-concept .multi-concept__head { padding: 5px 0 18px; }

.multi-concept .multi-concept__body { margin-bottom: 10px; -ms-flex: 1 1; flex: 1 1;}

.multi-concept .multi-concept__body-inner {display: -ms-flexbox;display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-pack: center; justify-content: center;}

.multi-concept .multi-concept__title { margin-bottom: 10px; font-family: var(--font-family-sans-serif-alt); font-size: 36px; text-transform: uppercase; color: var(--multi-concept-color); }

.multi-concept .multi-concept__title-footer { margin-bottom: 10px; font-family: var(--font-family-sans-serif-alt); font-size: 36px; text-transform: uppercase; color: var(--multi-concept-color); }

.multi-concept .multi-concept__title svg { width: 200px; }

.multi-concept .multi-concept__header { background-size: 220px; width: 220px; height: 150px; display: none; }

.multi-concept .multi-concept__footer { text-align: center;}

.multi-concept .multi-concept__store { width: 33%; padding: 0 7px; float: left; }

.multi-concept .multi-concept__store--2-per-row { width: 50%; }

.multi-concept .multi-concept__store .store__link { display: block; text-decoration: none; margin-bottom: 15px; }

.multi-concept .multi-concept__store .store__image { position: relative; overflow: hidden; display: block; margin-bottom: 5px;  height:0; padding-bottom: 100%; border-radius: 50%; text-align: center; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; background-color: white; }

.multi-concept .multi-concept__store .store__image--closed {opacity: .4;}

.multi-concept .multi-concept__store .store__image .svg-icon, .multi-concept .multi-concept__store .store__image .svg-icon div { }

.multi-concept .multi-concept__store .store__image svg {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 90%;
		height: 100%;
		-webkit-transform: translate(-50%, -50%);
		    -ms-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
	}

.multi-concept .multi-concept__store .store__title { font-size: 10px; font-weight: bold; text-align: center; color: var(--link-color) }

.multi-concept .multi-concept__store .store__closed { font-size: 8px; color: red; font-weight: bold; text-align: center; }

.multi-concept .multi-concept__powered-by svg {  }

/* ------------------------------------------------------------ *\
	Theme
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	Colors
\* ------------------------------------------------------------ */

:root {
    /* App */
    --background-color: white;
    --text-color: black;
    --border-color: #e5e5e5;
    /* Loading */
    --spinner-color: white;
    /* Intro Screen */
    --start-btn-background: #648b4e;
    --start-btn-text-color: white;
    --intro-link-color: #648b4e;
    --intro-text-color: black;
    --intro-background: url(/static/media/background.f4d47cb7.png);
    --select-language-color: #648b4e;
    /* Primary Buttons */
    --primary: #648b4e;
    --primaryButtonTextColor: #FFF;
    /* Header */
    --header-text-color: black;
    --header-btn-color: #648b4e;
    --header-background-color: white;
    /* Open Tab Header */
    --tab-open-color: #FDF09B;
    /*Categories*/
    --category-color: rgba(0, 0, 0, 0.6);
    /* Menu */
    --menu-background: transparent;
    --menu-title-color: #000;
    --menu-subcategory-color: #000;
    --menu-item-color: #000;
    --menu-item-popular-color: #F2C400;
    --menu-item-substantial-color: #F27400;
    --category-background: white;
    --selected-category-background: #648b4e;
    --selected-category-text-color: #fff;
    /* Product */
    --product-title: black;
    --product-question: black;
    --product-option-choice-background: #648b4e;
    --product-option-choice-check: transparent;
    --product-item-text-color: #555;
    --product-item-background-color: white;
    /* Suggestions/Addons */
    --suggestions-check-color: #648b4e;
    --suggestions-quantity-color: #648b4e;
    --suggestions-selected-color: #648b4e;
    --suggestions-background-color: white;
    /* Basket */
    --edit-icon-color: #648b4e;
    --edit-basket-color: #648b4e;
    --order-more-btn-background-color: white;
    --order-more-btn-text-color: #648b4e;
    --order-more-btn-border: #648b4e;
    --tip-border-color: #648b4e;
    --tip-background: #648b4e;
    --tip-text-color: #fff;
    --edit-basket-background-color: white;
    --basket-item-background-color: white;
    --basket-summary-background-color: white;
    --basket-actions-background-color: white;
    --basket-action-text-color: black;
    --basket-action-background-color: white;
    /* Checkout */
    --checkout-title: black;
    --checkout-survey: black;
    --checkout-background-color: #f0ebe7;
    /* Modal */
    --modal-title-color: #000;
    --modal-btn-text-color: #648b4e;
    --modal-btn-border: #648b4e;
    --modal-background-color: white;
    --modal-text-color: black;
    /* Payment */
    --payment-total-color: black;
    /* Modal Loyalty */
    --modal-loyalty-btn-border: #373A36;
    --modal-loyalty-btn-background-color: #373A36;
    --modal-loyalty-btn-text-color: #A11F33;
    --modal-loyalty-background-color: #373A36;
    --modal-loyalty-title-color: #D4B47C;
    --modal-loyalty-text-color: #FFFFFF;
    /* Loyalty Header */
    --loyalty-header-color: #DBB373;
    /* Text Colors */
    --white: #fff;
    --black: #000;
    /* Other */
    --info: #25a9bf;
    --default: #f0ebe7;
    --danger: #d71921;
    --grey: #555;
    --lightGrey: #707070;
    --unavailableGrey: #C7C7C7;
    /* Combo */
    --combo-tracker-color: #ED8736;
    --combo-header-color: #EFF2F4;
    /* Day Part Banner */
    --day-part-banner-bg-color: #FFFADC;
}

/* ------------------------------------------------------------ *\
	Settings
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	Settings
\* ------------------------------------------------------------ */

:root {
    --font-family-sans-serif : Gotham HTF, sans-serif;
    --font-family-sans-serif-alt : Brandon Grotesque, sans-serif;
    --font-family-sans-serif-alt2 : Sofia Pro, sans-serif;
    --font-family-serif : Times, 'Times New Roman', serif;

    --font-size-base : 14px;
    --line-height-base : 1.5;
    --line-height-base-em : 1.5em;

    --shell-max-width : 100vh;
    --shell-gutter : 15px;
    --shell-gutter-small : 10px;

    --responsive-xxs : 374px;
    --responsive-xs : 767px;
    --responsive-sm : 1023px;
    --responsive-md : 1200px;
}

/* ------------------------------------------------------------ *\
	Generic
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body { min-width: 320px; background: var(--background-color); background-size: cover; font-family: var(--font-family-sans-serif); font-size: var(--font-size-base); line-height: var(--line-height-base); font-weight: 300; color: var(--text-color); }

a { color: var(--black); text-decoration: none; }

a:hover,
a[href^="tel"] { text-decoration: underline; }

h1,
h2,
h3,
h4,
h5,
h6 { margin-bottom: calc(var(--line-height-base-em) / 2); }

h1 { font-size: 40px; }

h2 { font-size: 35px; }

h3 { font-size: 30px; }

h4 { font-size: 20px; }

h5 { font-size: 18px; }

h6 { font-size: 16px; }

p,
ul,
ol,
dl,
hr,
table,
blockquote { margin-bottom: calc(var(--line-height-base-em)); }

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child { margin-bottom: 0; }

/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

@font-face {
	font-family: 'Gotham';
	src: url(/static/media/Gotham_300_normal_1431009348.046308e7.eot);
	src: url(/static/media/Gotham_300_normal_1431009348.046308e7.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Gotham_300_normal_1431009348.d41d8cd9.svg#Gotham) format('svg'),
		url(/static/media/Gotham_300_normal_1431009348.ae2665f0.woff) format('woff'),
		url(/static/media/Gotham_300_normal_1431009348.c9f7f5fb.ttf) format('truetype');
	font-weight: 500;
	font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src: url(/static/media/Gotham_700_normal_1431009348.bce7d195.eot);
	src: url(/static/media/Gotham_700_normal_1431009348.bce7d195.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Gotham_700_normal_1431009348.d41d8cd9.svg#Gotham) format('svg'),
		url(/static/media/Gotham_700_normal_1431009348.e3bd86a5.woff) format('woff'),
		url(/static/media/Gotham_700_normal_1431009348.5d6f50ed.ttf) format('truetype');
	font-weight: bold;
	font-style: normal;
     font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src: url(/static/media/Gotham_500_normal_1431009348.5e10da7f.eot);
	src: url(/static/media/Gotham_500_normal_1431009348.5e10da7f.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Gotham_500_normal_1431009348.d41d8cd9.svg#Gotham) format('svg'),
		url(/static/media/Gotham_500_normal_1431009348.08fa98e6.woff) format('woff'),
		url(/static/media/Gotham_500_normal_1431009348.501537e6.ttf) format('truetype');
	font-weight: 300;
	font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham HTF';
    src: url(/static/media/GothamHTF-BookItalic.164d7c49.eot);
    src: url(/static/media/GothamHTF-BookItalic.164d7c49.eot?#iefix) format('embedded-opentype'),
        url(/static/media/GothamHTF-BookItalic.40265931.woff2) format('woff2'),
        url(/static/media/GothamHTF-BookItalic.a09a614f.woff) format('woff'),
        url(/static/media/GothamHTF-BookItalic.6dee4e2f.svg#GothamHTF-BookItalic) format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham HTF';
    src: url(/static/media/GothamHTF-Book.354ac7bc.eot);
    src: url(/static/media/GothamHTF-Book.354ac7bc.eot?#iefix) format('embedded-opentype'),
        url(/static/media/GothamHTF-Book.d79d2329.woff2) format('woff2'),
        url(/static/media/GothamHTF-Book.6ef7e4f9.woff) format('woff'),
        url(/static/media/GothamHTF-Book.7404175a.svg#GothamHTF-Book) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Gotham HTF';
	src: url(/static/media/GothamHTFMedium.6e4e5049.eot);
	src: url(/static/media/GothamHTFMedium.6e4e5049.eot?#iefix) format('embedded-opentype'),
		url(/static/media/GothamHTFMedium.b8cd9446.svg#Gotham) format('svg'),
		url(/static/media/GothamHTFMedium.75c92e7c.woff) format('woff'),
		url(/static/media/GothamHTFMedium.c84b2eac.ttf) format('truetype');
	font-weight: 500;
	font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src: url(/static/media/Gotham_400_normal_1431009348.adb86131.eot);
	src: url(/static/media/Gotham_400_normal_1431009348.adb86131.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Gotham_400_normal_1431009348.d41d8cd9.svg#Gotham) format('svg'),
		url(/static/media/Gotham_400_normal_1431009348.81665f3f.woff) format('woff'),
		url(/static/media/Gotham_400_normal_1431009348.e2f36b6c.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url(/static/media/BrandonGrotesque-Medium.08ee9db6.woff2) format('woff2'),
        url(/static/media/BrandonGrotesque-Medium.920c11f3.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url(/static/media/BrandonGrotesque-Black.1fa4147b.woff2) format('woff2'),
        url(/static/media/BrandonGrotesque-Black.33bc2fdf.woff) format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url(/static/media/BrandonGrotesque-Regular.aff015df.woff2) format('woff2'),
        url(/static/media/BrandonGrotesque-Regular.013a89eb.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

/*  Hidden  */

[hidden],
.hidden { display: none !important; }

/*  Alignleft  */

.alignleft { float: left; }

/*  Alignright  */

.alignright { float: right; }

/*  Disabled  */

[disabled],
.disabled { cursor: default; }

/*  Grid  */

.cols { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
}

.col { max-width: 100%; -ms-flex: 1 1 100%; flex: 1 1 100%; }

.col--1of2 { max-width: 50%; -ms-flex: 0 0 50%; flex: 0 0 50%; }

/*  Responsive Helpers  */

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block { display: none !important; }

@media (max-width: var(--responsive-xs)) {
	.hidden-xs { display: none !important; }
	.visible-xs-block { display: block !important; }
	.visible-xs-inline { display: inline !important; }
	.visible-xs-inline-block { display: inline-block !important; }
}

@media (min-width: calc(var(--responsive-xs) + 1px)) and (max-width: var(--responsive-sm)) {
	.hidden-sm { display: none !important; }
	.visible-sm-block { display: block !important; }
	.visible-sm-inline { display: inline !important; }
	.visible-sm-inline-block { display: inline-block !important; }
}

@media (min-width: calc(var(--responsive-sm) + 1px)) and (max-width: var(--responsive-md)) {
	.hidden-md { display: none !important; }
	.visible-md-block { display: block !important; }
	.visible-md-inline { display: inline !important; }
	.visible-md-inline-block { display: inline-block !important; }
}

@media (min-width: calc(var(--responsive-md) + 1px)) {
	.hidden-lg { display: none !important; }
	.visible-lg-block { display: block !important; }
	.visible-lg-inline { display: inline !important; }
	.visible-lg-inline-block { display: inline-block !important; }
}

.arrow-left:before {
	content: "\f060";
}

[dir="rtl"] .arrow-left:before {
	content: "\f061";
}

/* ------------------------------------------------------------ *\
	Reset
\* ------------------------------------------------------------ */

* { padding: 0; margin: 0; outline: 0; -webkit-box-sizing: border-box; box-sizing: border-box; }

*:before,
*:after { -webkit-box-sizing: inherit; box-sizing: inherit; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main { display: block; }

template { display: none; }

html { -webkit-tap-highlight-color: rgba(0,0,0,0); tap-highlight-color: rgba(0,0,0,0); }

body { -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-smoothing: antialiased; -webkit-text-size-adjust: none; -moz-text-size-adjust: none; -ms-text-size-adjust: none; text-size-adjust: none; }

img,
iframe,
video,
audio,
object { max-width: 100%; }

img,
iframe { border: 0 none; }

img { height: auto; display: inline-block; vertical-align: middle; }

b,
strong { font-weight: bold; }

address { font-style: normal; }

svg:not(:root) { overflow: hidden; }

a,
button,
.btn,
.field[type="submit"],
.field[type="button"],
.field[type="reset"],
.field[type="file"],
.field[type="image"],
label[for] { cursor: pointer; }

.btn[href^="tel"],
a[href^="tel"],
.btn[disabled],
button[disabled],
.field[disabled],
.textarea[disabled],
.select select[disabled] { cursor: default; }

button::-moz-focus-inner,
.field::-moz-focus-inner { padding: 0; border: 0; }

.field[type="text"],
.field[type="password"],
.field[type="date"],
.field[type="datetime"],
.field[type="datetime-local"],
.field[type="month"],
.field[type="week"],
.field[type="email"],
.field[type="number"],
.field[type="search"],
.field[type="tel"],
.field[type="time"],
.field[type="url"],
.field[type="color"],
.textarea,
.btn[href^="tel"],
a[href^="tel"] { -webkit-appearance: none; -moz-appearance: none; appearance: none; }

.field[type="search"]::-webkit-search-cancel-button,
.field[type="search"]::-webkit-search-decoration { -webkit-appearance: none; appearance: none; }

.textarea { overflow: auto; -webkit-overflow-scrolling: touch; overflow-scrolling: touch; }

button,
button,
.field,
optgroup,
.select select,
.textarea { font-family: inherit; font-size: inherit; color: inherit; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-smoothing: antialiased; }

button,
select { text-transform: none; }

table { width: 100%; border-collapse: collapse; border-spacing: 0; }

nav ul,
nav ol { list-style: none outside none; }

/* ------------------------------------------------------------ *\
	Modules
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	Banner
\* ------------------------------------------------------------ */

.banner { position: relative; display: block; height: 195px; margin-bottom: 10px; padding: 0; background-repeat: no-repeat; background-size: cover; background-position: center center; border-radius: 2px; text-decoration: none; }

.banner:hover { text-decoration: none; }

.banner:last-child { margin-bottom: 0; }

.banner .banner__caption {
		position: absolute;
		left: 0;
		bottom: 0;
		padding: 5px 13px;
		border-radius: 2px;
		background: var(--category-color);
		font-family: var(--font-family-sans-serif-alt);
		font-size: 16px;
		font-weight: 900;
		text-transform: uppercase;
		color: var(--primaryButtonTextColor);
	}

[dir="rtl"] .banner .banner__caption { left:auto; right:0; }

/* ------------------------------------------------------------ *\
	Banner No Image
\* ------------------------------------------------------------ */

.banner-no-image {
	height: auto;
}

.banner-no-image .banner__caption { position: static; background: var(--primary); }

.banner-2-column {
	float: left;
	width: calc(50% - 6px);
	margin-left: 3px;
	margin-right: 3px;
	height: 0;
	padding-bottom: calc(50% - 6px);
}

.banner-2-column .banner__caption {
	text-align: center;
	width: 100%;
}

/* ------------------------------------------------------------ *\
	Boxes
\* ------------------------------------------------------------ */

.boxes {   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */ 
-ms-flex-wrap: wrap; 
    flex-wrap: wrap; -ms-flex-pack: start; justify-content: flex-start; -ms-flex-align: start; align-items: flex-start; margin: 0 -5px -10px; }

.boxes .box { max-width: 50%; -ms-flex: 0 0 50%; flex: 0 0 50%; padding: 0 5px 10px; }

/* ------------------------------------------------------------ *\
	Box
\* ------------------------------------------------------------ */

.box .box__field { display: none; }

.box .box__inner { display: block; border-radius: 2px; overflow: hidden; border: 1px solid; border-color: var(--border-color); -webkit-transition: border-color .3s; -o-transition: border-color .3s; transition: border-color .3s; }

.box .box__field:checked ~ .box__inner { border-color: var(--suggestions-selected-color); }

.box .box__field:checked ~ .box__inner .box__icon { background: #fff; }

.box .box__field:checked ~ .box__inner .box__icon:after { content: '\f00c'; color: var(--suggestions-check-color); }

.box .box__field:checked ~ .box__inner .box__qty { display: block; }

.box .box__image { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    height: 138px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.box .box__content { background: #fff; padding: 6px 4px 3px 7px; border-width: 0 1px 1px 1px; background-color: var(--suggestions-background-color); }

.box .box__title { min-height: 30px; font-size: 12px; line-height: 1; font-weight: 500; }

.box .box__meta { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
}

.box .box__price { font-size: 12px; line-height: 1; font-weight: 500; }

.box .box__icon { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: var(--suggestions-check-color);
    border: 1px solid var(--suggestions-check-color);
    -webkit-transition: background .3s;
    -o-transition: background .3s;
    transition: background .3s;
}

.box .box__icon:after { content: '\f067'; display: inline-block; margin-top: 1px; font-style: normal; font-variant: normal; text-rendering: auto; -webkit-font-smoothing: antialiased; font-family: 'Font Awesome 5 Free'; font-size: 12px; font-weight: 900; color: var(--white); -webkit-transition: color .3s; -o-transition: color .3s; transition: color .3s; }

.box .box__qty { display: none; max-width: 96px; }

/* ------------------------------------------------------------ *\
	Btn
\* ------------------------------------------------------------ */

.btn {
    position: relative;
    display: inline-block;
    padding: 13px 10px;
    background: var(--primary);
    border-radius: 4px;
    border: none;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    text-decoration: none;
    color: var(--primaryButtonTextColor);
    -webkit-transition: background .3s, color .3s;
    -o-transition: background .3s, color .3s;
    transition: background .3s, color .3s;
}

.btn:hover { text-decoration: none; }

.btn:disabled,
.btn.btn--disabled { opacity: .5; }

.btn--block { display: block; min-width: 100%; max-width: 100%; width: 100%; }

.btn--tall { display: -ms-flexbox; display: flex; -ms-flex-pack: center; justify-content: center; -ms-flex-align: center; align-items: center; min-width: 300px; max-width: 100%; width: 100%; height: 110px; }

.btn--flex-between { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-pack: justify;
        justify-content: space-between;
    padding: 13px 15px;
}

.btn--md { min-width: 248px; }

/*.btn--secondary { background: #fff; border: 1px solid var(--header-btn-color); color: var(--header-btn-color); }*/

.btn--transparent { background: transparent; border: 1px solid var(--modal-btn-border); color: var(--modal-btn-text-color); }

.btn--transparent-2 { background: transparent; border: 1px solid var(--order-more-btn-border); color: var(--order-more-btn-text-color); }

.btn--secondary { background: #fff; border: 1px solid var(--modal-btn-border); color: var(--modal-btn-text-color); }

.btn--secondary-2 { background: var(--order-more-btn-background-color); border: 1px solid var(--order-more-btn-border); color: var(--order-more-btn-text-color); }

.btn--secondary-3 { background: var(--modal-loyalty-btn-background-color); border: 1px solid var(--modal-loyalty-btn-border); color: var(--modal-loyalty-btn-text-color); }

/* ------------------------------------------------------------ *\
	Cart
\* ------------------------------------------------------------ */

.cart { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex: 1 1;
        flex: 1 1;
    -ms-flex-direction: column;
        flex-direction: column;
}

.cart .cart__head { color:var(--header-text-color); margin-bottom: 21px; padding: 22px 0 0; font-size: 12px; font-weight: 500; text-align: center; background-color:var(--header-background-color);}

.cart .cart__head-inner { position: relative; margin-bottom: 22px; padding: 0 30px; }

.cart .cart__head-btn { padding-right: 12px; color: var(--header-btn-color); }

.cart .cart__head-btn-container { position: absolute; left: 0; top: 0px; padding: 10px 10px 10px 6px; background: none; border: none; font-size: 17px;}

[dir="rtl"] .cart .cart__head-btn-container { right: 0; left: initial; direction: ltr; }

.cart .cart__body {-ms-flex: 1 1;flex: 1 1}

.cart .cart__body thead { background-color: var(--edit-basket-background-color);}

.cart .cart__body tbody { background-color: var(--basket-item-background-color);}

.cart .cart__title { font-family: var(--font-family-sans-serif-alt); font-size: 16px; font-weight: 500; text-transform: uppercase; }

.cart .cart__actions .btn + .btn { margin-top: 10px; }

.cart .cart__section { margin-bottom: 16px; }

.cart .cart__section--alt { margin-bottom: 30px; }

.cart .cart__section--modified { margin-bottom: 50px; }

.cart .cart__section-inner { padding: 0 25px; }

.cart .cart__section-actions { margin-top: 11px; }

.cart .cart__section-title { margin-bottom: 9px; font-size: 14px; }

.cart .cart__foot { position: -webkit-sticky; position: sticky; bottom: 0; left: 0; z-index: 30; padding: 10px 0; background: var(--background-color); background-size: cover; }

/* ------------------------------------------------------------ *\
	Cart Calculator
\* ------------------------------------------------------------ */

.cart-calculator { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    border-width: 1px 0;
    border-style: solid;
    border-color: var(--border-color);
}

.cart-calculator .cart-calculator__group { max-width: 50%; -ms-flex: 0 0 50%; flex: 0 0 50%; background-color: var(--basket-summary-background-color);  }

.cart-calculator .cart-calculator__group--borderless { max-width: 50%; -ms-flex: 0 0 50%; flex: 0 0 50%; }

.cart-calculator .cart-calculator__group + .cart-calculator__group { border-left: 1px solid; border-color: var(--border-color); }

.cart-calculator .cart-calculator__group--flex { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center;
    }

.cart-calculator .cart-calculator__group-inner { max-width: 100%; -ms-flex: 1 1 100%; flex: 1 1 100%; padding: 23px 16px; background-color: var(--basket-actions-background-color); }

.cart-calculator .cart-calculator__list-btns { list-style-type: none; border-radius: 2px; border: 1px solid; border-color: var(--border-color); background-color: var(--basket-action-background-color); color: var(--basket-action-text-color); }

.cart-calculator .cart-calculator__list-btns li + li { border-top: 1px solid; border-color: var(--border-color); }

.cart-calculator .cart-calculator__btn { display: block; width: 100%; padding: 10px; border: none; background: none; font-size: 12px; line-height: 1.33; font-weight: 500; }

.cart-calculator .cart-calculator_loaded_value_account_btn {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    background-color: var(--primary);
    color: white;
    font-size: 12px;
    line-height: 1.33;
    font-weight: 500;
}

.cart-calculator .cart-calculator__btn_border_primary {
    position: relative;
    border: 1px solid var(--primary);
    background-color: white;
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 12px;
    line-height: 1.33;
    font-weight: 500;
    color: var(--primary);
}

.cart-calculator .cart-calculator__btn_border_primary .airline-employee-discount {
    font-weight: normal;
    color: black;
}

.cart-calculator .cart-calculator__btn .airline-employee-discount {
    font-weight: normal
}

.cart-calculator .cart-calculator__btn_border_primary .X {
    display: inline-block;
    color: var(--danger);
    font-size: 1em;
    line-height: 1;
    margin-right: 1px;
    margin-top: 1px;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    position: absolute;
    top: 0;
    right: 0;
}

.cart-calculator .cart-calculator__list { list-style-type: none; padding: 13px 15px 15px 38px; font-size: 12px; font-weight: 500;}

[dir="rtl"] .cart-calculator .cart-calculator__list { padding: 13px 38px 15px 15px;}

.cart-calculator .cart-calculator__list li { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
        -ms-flex-pack: justify;
            justify-content: space-between;
        -ms-flex-line-pack: center;
            align-content: center;
    }

.cart-calculator .cart-calculator__list li .cart-calculator-removeable {
    position: relative; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    cursor: pointer;
}

.cart-calculator .cart-calculator__list li .cart-calculator-item-remove {
    position: absolute;
    left: -20px;
    margin-right: 5px;
    border: solid 1px #CCC;
    padding-left: 4px;
    padding-top: 1px;
    border-radius: 50%;
    /* right: 12px; */
    /* top: 12px; */
    width: 12px;
    height: 12px;
}

.cart-calculator .cart-calculator__list li .cart-calculator-item-remove:before, 
.cart-calculator .cart-calculator__list li .cart-calculator-item-remove:after {
    position: absolute;
    /* left: 15px; */
    content: ' ';
    height: 7px;
    width: 2px;
    background-color: var(--danger);
}

.cart-calculator .cart-calculator__list li .cart-calculator-item-remove:before {
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
}

.cart-calculator .cart-calculator__list li .cart-calculator-item-remove:after {
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.cart-calculator .cart-calculator__list .discount { position: relative; text-transform: uppercase; }

.cart-calculator .cart-calculator__list .discount:before { content: '\f056'; position: absolute; right: calc(100% + 10px); top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); font-style: normal; font-variant: normal; text-rendering: auto; -webkit-font-smoothing: antialiased; font-family: 'Font Awesome 5 Free'; font-size: 12px; font-weight: 900; color: var(--danger); }

.cart-calculator .cart-calculator__list .success { color: var(--primary); }

.cart-calculator .cart-calculator__list .danger { font-size: 14px; color: var(--danger); }

.cart-calculator .cart-calculator__list .deduction {
    color: var(--primary);
}

/* ------------------------------------------------------------ *\
	Checkout
\* ------------------------------------------------------------ */

.checkout {  background: var(--checkout-background-color); font-size: 14px; line-height: 1.43; font-weight: 300; text-align: center; -ms-flex: 1 1; flex: 1 1; }

.checkout .checkout__inner { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center;
        min-height: 100%;
        max-width: 324px;
        margin: 0 auto;
        padding: 20px 0;
    }

.checkout .checkout__logo { margin-bottom: 25px; }

.checkout .checkout__title { font-size: 20px; color: var(--checkout-title) }

.checkout .checkout__table { font-size: 16px; margin-bottom: 25px; }

.checkout .checkout__content { margin-bottom: 30px; }

.checkout .checkout__collect { border:2px solid; font-size:20px; border-color:#FF9000; padding-left: 20px; padding-top:10px; padding-bottom:10px; padding-right: 20px; margin-top: 30px; font-weight:bold; color: black; background-color:#fff; }

.checkout .fa-wine-glass {
    color: #FF9000
}

.checkout .fa-exclamation {
    line-height: 22px;
}

.checkout .checkout__proof { margin-top: 30px; font-style: italic; }

.checkout .checkout__delivery { width: 100%; max-width: 295px; margin-bottom: 30px;}

.checkout .checkout__delivery a { color: var(--text-color); text-decoration:underline; }

.checkout .checkout__actions { width: 100%; max-width: 205px; margin: 0 auto; }

.checkout .checkout__survey { width: 100%; max-width: 295px; margin-bottom: 30px; }

.checkout .checkout__survey-questions { max-width: 295px; margin: 0 auto; margin-top: 30px; }

.checkout .checkout__survey a { color: var(--checkout-survey); text-decoration:underline; font-weight:bold}

.checkout .checkout__actions .btn + .btn { margin-top: 10px; }

.checkout .challenge_modal {width:250px; height:400px}

.checkout .challenge_iframe {
    width: 250px;
    height: 400px
}

/* ------------------------------------------------------------ *\
	Combo
\* ------------------------------------------------------------ */

.combo {
    font-size: 12px;
    line-height: 1.1;
    font-weight: 300;
}

.combo .combo__breadcrumbs {
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: var(--border-color);
        padding: 15px 15px 15px 15px;
        background-color: var(--combo-header-color);
        position: -webkit-sticky;
        position: sticky;
        z-index: 30;
        top: 0px;
    }

.combo .combo__btn {
        padding: 10px;
        position: absolute;
        left: 16px;
        top: 26px;
        background: none;
        border: none;
        font-size: 16px;
        color: black;
    }

.combo .combo__title {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 500;
        color: var(--product-question);
    }

.combo .combo__title--main {
        color: var(--product-title);
    }

.combo .combo__title--alt {
        margin-bottom: 11px;
        padding: 0 15px;
    }

.combo .combo__title--alt span {
            color: var(--danger);
        }

.combo .combo__group {
        margin-bottom: 26px;
    }

.combo .combo__item {
        padding: 20px 0 16px;
        display: -ms-flexbox;
        display: flex;
    }

.combo .combo__content {
        max-width: calc(100% - 85px);
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-negative: 1;
        flex-shrink: 1;
        -ms-flex-preferred-size: calc(100% - 85px);
        flex-basis: calc(100% - 85px);
        padding-top: 9px;
    }

.combo .combo__content-full-width {
        max-width: 100%;
        -ms-flex-positive: 1;
            flex-grow: 1;
        -ms-flex-negative: 1;
            flex-shrink: 1;
        -ms-flex-preferred-size: calc(100% - 85px);
            flex-basis: calc(100% - 85px);
        padding-top: 9px;
    }

.combo .combo__image {
        max-width: 75px;
        height: 75px;
        -ms-flex: 0 0 75px;
        flex: 0 0 75px;
        margin-left: 10px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        border-radius: 2px;
    }

.combo .combo__holder {
        padding: 17px 0;
    }

.combo .combo__section--alt {
        margin-top: 60px;
        padding: 0 15px;
    }

.combo .combo__qty {
        padding: 15px 0;
        border-width: 1px 0;
        border-style: solid;
        border-color: #e5e5e5
    }

.combo .combo__entry {
        margin-bottom: 10px;
    }

.combo .combo__entry:last-child {
            margin-bottom: 0;
        }

.combo .combo__section--alt .btn + .btn {
        margin-top: 10px;
    }

/* ------------------------------------------------------------ *\
	Step
\* ------------------------------------------------------------ */

.combo .steps {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

.combo .step {
        position: relative;
        width: 33.33%;
        text-align: center;
    }

.combo .step .step__number {
            width: 40px;
            height: 40px;
            margin: 0 auto;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-flex-align: center;
            align-items: center;
            border: 2px solid #CCCCCC;
            border-radius: 50%;
            background: #fff;
            font-size: 18px;
            font-weight: 700;
            color: #CCCCCC;
        }

.combo .step .step__entry {
            color: #CCCCCC;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            padding-top: 5px;
        }

.combo .step.active .step__number {
            background: var(--combo-tracker-color);
            border-color: var(--combo-tracker-color);
            color: #fff;
        }

.combo .step.active .step__entry {
            padding-top: 5px;
            color: var(--combo-tracker-color);
        }

.combo .step + .step:before {
            content: '';
            position: absolute;
            top: 20px;
            left: -45%;
            width: 90%;
            height: 4px;
            border-top: 2px solid #CCCCCC;
            z-index: -1;
        }

.combo .step.active:before {
            border-color: var(--combo-tracker-color);
        }

.combo .step.current .step_underline {
            position: absolute;
            bottom: -4px;
            margin: 0 auto;
            width: 40%;
            left: 30%;
            border-bottom: 3px solid var(--combo-tracker-color);
        }

/* ------------------------------------------------------------ *\
	Existing Tab
\* ------------------------------------------------------------ */

.existing-tab { font-size: 12px; text-align: left; border-bottom: 1px solid; border-color:var(--border-color); }

.existing-tab--expanded {border-bottom:none; }

.existing-tab .existing-tab__title { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-pack: start;
        justify-content: flex-start;
    -ms-flex-align: center;
        align-items: center;
    background: none;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
}

.existing-tab th { font-size: 12px; font-weight: 300; }

.existing-tab th.qty { text-align: right; padding-right: 7px; }

.existing-tab th.price { text-align: right; color:var(--edit-icon-color); }

.existing-tab strong.block { display: block; }

.existing-tab td span { display: block; font-size: 10px; line-height: 1; }

.existing-tab th { padding: 6px 0; border-top: 1px solid; border-color:var(--border-color); }

.existing-tab td { vertical-align: top; padding: 10px 0; border-width: 1px 0; border-style: solid; border-color:var(--border-color); }

.existing-tab th:first-child,
.existing-tab td:first-child { padding-left: 15px; }

.existing-tab th:last-child,
.existing-tab td:last-child { padding-right: 15px; }

.existing-tab td.name { position: relative; width: 62%; padding-right: 20px;  color: #B4B4B4 }

.existing-tab td.name strong { margin-bottom: 4px; }

.existing-tab .btn-edit { position: absolute; left: 17px; top: 17px; background: none; border: none; }

.existing-tab .btn-edit .icon { font-size: 14px; color: var(--edit-icon-color); }

.existing-tab td.qty { width: 82px; padding-right: 7px; text-align: right;  color: #B4B4B4 }

.existing-tab td.subtotal-title { width: 82px; padding-right: 7px; text-align: right; }

.existing-tab td.price { text-align: right; width: 55px;  color: #B4B4B4 }

.existing-tab td.subtotal { text-align: right; width: 55px; }

/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */

/*  Field  */

.field {border-radius:0px !important; border-top:none  !important; border-right:none  !important; border-left:none  !important; display: block; width: 100%; height: 40px; border-width: 0 0 1px 0; border-style: solid; border-color: #8f8f8f; font-size: 16px; font-weight: 300; text-align:center; }

.field-invalid{border-color: red;}

/* Field With Symbol*/

.field-preceder {
    display: inline-block;
    width: auto;
    height: 40px;
    border-width: 0 0 0 0;
    font-size: 16px;
    font-weight: 300;
}

.field-preceder + .field
{
    width:auto;
    display:inline-block;
}

/*  Checkbox  */

.checkbox { font-size: 14px; font-weight: 300; line-height: 16px; color: var(--product-item-text-color); background-color:var(--product-item-background-color);  }

.checkbox input {  position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0); white-space: nowrap; /* added line */ border: 0; }

.checkbox label { position: relative; display: block; padding: 13px 40px 13px 50px; border-bottom: 1px solid; border-color:var(--border-color); }

.checkbox:first-child label { border-top: 1px solid; border-color:var(--border-color); }

.checkbox label:before {  position: absolute; right: 23px; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); font-family: 'Font Awesome 5 Free'; font-size: 14px;  }

.checkbox label:after { content: '\f0c8'; position: absolute; right: 23px; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); font-family: 'Font Awesome 5 Free'; font-size: 14px; color: var(--black); }

.checkbox .hide:after { content:''}

[dir="rtl"] .checkbox label:after {left: 23px; right: initial;}

.checkbox input:checked ~ label:before { content: '\f14a'; font-weight: 500; color: var(--product-option-choice-check); }

.checkbox input:checked ~ label:after { content: '\f14a'; font-weight: 900; color: var(--product-option-choice-background); }

/*  Radio  */

.radio { font-size: 14px; font-weight: 300; line-height: 16px; color: var(--product-item-text-color); background-color:var(--product-item-background-color); }

.radio input { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0); white-space: nowrap; border: 0; }

.radio label { position: relative; display: block; padding: 13px 40px 13px 50px; border-bottom: 1px solid; border-color:var(--border-color); }

.radio:first-child label { border-top: 1px solid; border-color:var(--border-color); }

.radio label:after { content: '\f111'; position: absolute; left: 23px; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); font-family: 'Font Awesome 5 Free'; font-size: 14px; color: var(--product-option-choice-background); }

.radio input:checked ~ label:after { content: '\f192'; color: var(--product-option-choice-background); }

/*  Radio Group  */

.radio-group { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    margin: 0 -5px;
}

.radio-group .radio-square { max-width: 33.33%; -ms-flex: 0 0 33.33%; flex: 0 0 33.33%; padding: 0 5px; }

/*  Radio Group Stack  */

.radio-group--stack { display: block; margin: 0; }

.radio-group--stack .radio-square--stack { max-width: 100%; -ms-flex: 1 1 100%; flex: 1 1 100%; padding: 0; }

.radio-group--stack .radio-square--stack + .radio-square--stack { margin-top: 10px; }

/*  Radio Square Stack  */

.radio-square--stack strong { margin: 0; }

/*  Radio Square  */

.radio-square input { display: none; }

.radio-square label { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    padding: 8px 5px 12px;
    border-radius: 6px;
    border: 1px solid #e3e3e3;
    background: #fff;
    -webkit-transition: border-color .3s, background .3s, color .3s;
    -o-transition: border-color .3s, background .3s, color .3s;
    transition: border-color .3s, background .3s, color .3s;
}

.radio-square input:checked ~ label { border-color: var(--tip-border-color); background: var(--tip-background); color: var(--tip-text-color); }

.radio-square label span,
.radio-square label strong { display: block; }

.radio-square label strong { margin-bottom: 2px; font-size: 16px; line-height: 1; }

.radio-square label span { font-size: 12px; line-height: 1; }

/*  Qty Field  */

.quantity { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    max-width: 150px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 4px;
}

.quantity > * { display: block; max-width: 33.33%; height: 38px; -ms-flex: 1 1 33.33%; flex: 1 1 33.33%; }

.quantity .quantity__btn { position: relative; display: block; background: #f8f8f8; border: none; font-size: 16px; color: #555555; cursor: pointer; -webkit-transition: -webkit-transform .3s; transition: -webkit-transform .3s; -o-transition: transform .3s; transition: transform .3s; transition: transform .3s, -webkit-transform .3s; }

.quantity .quantity__btn-hidden { -webkit-transform: translateX(100%); -ms-transform: translateX(100%); transform: translateX(100%); }

.quantity .quantity__btn--minus { border-width: 1px 0 1px 1px; border-style: solid; border-color: #e5e5e5; border-radius: 4px 0 0 4px; }

.quantity .quantity__btn--plus { position: relative; z-index: 10; border-width: 1px 1px 1px 0; border-style: solid; border-color: #e5e5e5; border-radius: 0 4px 4px 0; }

.quantity .quantity__field { position: relative; z-index: 10; display: block; background: #fff; border: 1px solid #e5e5e5; font-family: var(--font-family-sans-serif); font-size: 20px; font-weight: 700; text-align: center; color: #555555; }

/*  Qty Field Small  */

.quantity--small > * { height: 32px; }

/*  Qty Field Small Alt  */

.quantity--small-alt { max-width: 78px; }

.quantity--small-alt > * { height: 24px; }

.quantity--small-alt .quantity__btn { max-width: 24px; -ms-flex: 1 1 24px; flex: 1 1 24px; font-size: 14px; }

.quantity--small-alt .quantity__field { max-width: 30px; -ms-flex: 1 1 30px; flex: 1 1 30px; font-size: 16px; }

/*  Qty Field Primary  */

.quantity--primary { border: none; }

.quantity--primary .quantity__btn { background: var(--suggestions-quantity-color); border: none; color: #fff; }

/*  Qty Field Secondary  */

.quantity--secondary .quantity__btn,
.quantity--secondary { border: none; }

.quantity--secondary .quantity__btn { border-radius: 6px; }

.quantity--secondary .quantity__field { border: none; }

select {
    position: relative;
    background-color: white;
    border: 1px solid #e5e5e5;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    -webkit-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
    border-radius: 4px;
    max-width: 250px;
}

.select { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 4px;
}

/* ------------------------------------------------------------ *\
	Freedom Pay
\* ------------------------------------------------------------ */

.freedom-pay-dropin {

}

.freedom-pay-dropin iframe{
    width: 100%;
}

.freedom-pay-dropin .freedom-pay-icon--bordered {
    background: #FFFFFF;
    border: 1px solid #BBBBBB;
    border-radius: 3px;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
}

.freedom-pay-dropin .freedom-pay-options-list {
    margin: 0 auto;
    padding-bottom: 10px;
    width: 100%;
}

.freedom-pay-dropin .freedom-pay-option:first-child {
    border-radius: 4px 4px 0 0;
    border-top-width: 1px;
}

.freedom-pay-dropin .freedom-pay-option:last-child {
    border-radius: 0 0 4px 4px;
}

.freedom-pay-dropin .freedom-pay-option {
    -ms-flex-align: center;
        align-items: center;
    background-color: #FAFAFA;
    border-color: #B5B5B5;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    padding: 12px 10px;
}

.freedom-pay-dropin .freedom-pay-option .freedom-pay-option__label {
    color: #000000;
    font-size: 16px;
    margin-left: 20px;
    width: 100%;
}

.freedom-pay-dropin .freedom-pay-option .freedom-pay-option__logo {
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    -ms-flex-align: center;
        align-items: center;
}

.freedom-pay-dropin .freedom-pay-option .freedom-pay-option__logo-svg {
   width: 48px;
   height: 29px;
}

.freedom-pay-dropin .freedom-pay-heading {
    color: #000000;
    font-size: 16px;
    margin-bottom: 10px;
}

.freedom-pay-dropin .freedom-pay-large-button {
    background: #FAFAFA;
    border-radius: 4px;
    color: #000000;
    cursor: pointer;
    font-size: 14px;
    margin: 0 auto;
    padding: 18px;
    text-align: center;
    width: 100%;
    text-decoration: underline;
}

.freedom-pay-dropin .freedom-pay-sheet {
    background-color: white;
    border: 1px solid #B5B5B5;
    border-radius: 4px;
    margin: 0 auto;
    max-height: 700px;
    -webkit-transition: opacity 300ms, max-height 300ms ease, -webkit-transform 300ms;
    transition: opacity 300ms, max-height 300ms ease, -webkit-transform 300ms;
    -o-transition: transform 300ms, opacity 300ms, max-height 300ms ease;
    transition: transform 300ms, opacity 300ms, max-height 300ms ease;
    transition: transform 300ms, opacity 300ms, max-height 300ms ease, -webkit-transform 300ms;
    width: 100%;
    margin-bottom: 10px;
}

.freedom-pay-dropin .freedom-pay-sheet__header {
    -ms-flex-align: center;
        align-items: center;
    border-bottom: 1px solid #B5B5B5;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 12px 15px 0 12px;
    position: relative;
}

.freedom-pay-dropin .freedom-pay-sheet__header .freedom-pay-sheet__header-label {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 1;
        flex-grow: 1;
    padding-bottom: 12px;
}

.freedom-pay-dropin .freedom-pay-sheet__header .freedom-pay-sheet__logo--header {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    width: 50px;
}

.freedom-pay-dropin .freedom-pay-sheet__header .freedom-pay-sheet__label {
    font-size: 16px;
    margin-left: 20px;
}

.freedom-pay-dropin .freedom-pay-sheet__header .freedom-pay-option__logo-svg {
    width: 48px;
    height: 29px;
}

.freedom-pay-dropin .freedom-pay-sheet__content--form {
    padding: 10px 15px 10px 10px;
}

.freedom-pay-dropin .freedom-pay-sheet__header .freedom-pay-sheet__icons {
    padding-bottom: 10px;
}

.freedom-pay-dropin .freedom-pay-sheet__header .freedom-pay-sheet__card-icon {
    display: inline-block;
    padding-left: 5px;
}

/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header { padding: 22px 0 0; position:-webkit-sticky; position:sticky; top:0px; z-index: 30; background-color: var(--header-background-color); }

.header .header__content { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
        -ms-flex-pack: justify;
            justify-content: space-between;
        -ms-flex-align: center;
            align-items: center;
    }

.header .header__btn { margin-left:0px; padding: 6px; background: none; border: none; font-size: 17px; color: var(--header-btn-color); }

[dir="rtl"] .header .header__btn-back {float:right;}

.header .header__btn-cart { margin-right:11px; position: relative; }

.header .header__btn-cart span.dot { position: absolute; left: 50%; top: 50%; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); width: 7px; height: 7px; border-radius: 50%; background: #d0021b; }

.header .header__logo { display: block; }

.header .header__logo svg { width: 157px; height: 40px; }

.ending-banner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    background-color: #FFFADC;
    color: black;
    height: 44px;
    font-size: 12px;
}

.small-ending-banner {
    height: 34px;
    margin-bottom: 11px;
}

.header .ending-banner div:first-child {
    margin-top: 2px;
    margin-right: 5px;
}

.wait-time-msg { font-size: 14px; display: -ms-flexbox; display: flex; -ms-flex-pack: center; justify-content: center; -ms-flex-align: center; align-items: center; }

.wait-time-msg span { font-weight: 800; }

.wait-time-alert { color: var(--danger)}

/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */

.intro { -ms-flex-positive: 1; flex-grow: 1; padding-bottom: 16px; font-size: 16px; font-weight: normal; text-align: center; background: var(--intro-background); color: var(--intro-text-color); }

.intro .intro__head { padding: 20px 0 18px; }

.intro .intro__logo { display: block; }

.intro .intro__entry { margin-bottom: 12px; }

.intro .intro__entry:last-child { margin-bottom: 0; }

.intro .intro__title { margin-bottom: 9px; font-family: var(--font-family-sans-serif-alt); font-size: 26px; text-transform: uppercase; color: var(--primary); }

.intro .intro__slider { margin-bottom: 20px; }

.intro .intro__hint { margin-bottom: 17px; letter-spacing: 0.02em; }

.intro .intro__outro { font-size: 12px; font-style: italic;     padding-bottom: 10px; }

.intro .intro__text {
    font-size: 12px;
    margin-bottom: 20px;
}

.intro .intro__body { margin-bottom: 20px; }

.intro .intro__outro a {
    color: var(--intro-link-color);
    text-decoration: underline;
}

.intro__actions .multi__primary {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
}

.intro__actions .multi__primary .btn:nth-child(2) {
    margin-top: 1rem;
}

.intro__actions .btn {
    font-size: 20px;
    width: 286px;
    background: var(--start-btn-background);
    color: var(--start-btn-text-color);
}

.intro__actions .btn + br + .btn {
    margin-top: 10px;
    font-size: 20px;
    width: 286px;
    background: transparent;
    border: 1px solid var(--start-btn-background);
    color: var(--start-btn-background);
}

/* ------------------------------------------------------------ *\
	Item Menu
\* ------------------------------------------------------------ */

.item-menu { display: block; margin-bottom: 14px; font-size: 12px; line-height: 1.2; font-weight: 300; text-decoration: none; color: var(--lightGrey); }

.item-menu:hover { text-decoration: none; }

.item-menu:last-child { margin-bottom: 0; }

.item-menu .item-menu__title { margin-bottom: 5px; margin-top: 5px; font-family: var(--font-family-sans-serif-alt); text-transform: uppercase; color: var(--menu-title-color); }

.item-menu.item-disabled .item-menu__title { color: var(--unavailableGrey); }

.item-menu .item-menu__label { display: -ms-inline-flexbox; display: inline-flex; -ms-flex-align: center; align-items: center; margin-left: 5px; font-size: 9px; font-weight: 700; text-transform: uppercase; }

.item-menu .item-tag-popular { color: var(--menu-item-popular-color); }

.item-menu .item-tag-substantial { color: var(--menu-item-substantial-color); }

.item-menu .item-menu__label span { margin-left: 2px; margin-top: 2px; }

.item-menu > .item-menu__head { padding-bottom: 9px; padding-top: 9px; border-bottom: 1px solid var(--border-color); }

.item-menu .item-menu__container { padding-left: 30px; }

[dir="rtl"] .item-menu .item-menu__container { padding-left: 0px; padding-right: 30px; }

.item-menu .timer-ellipse {
    width: 22px;
    position: absolute;
    top: -5px;
    right: -20px;
    background-color: #FFFADC;
    border: 1px solid #DCA741;
    border-radius: 50%;
    padding: 3px 5px 0px 5px 
}

.item-menu .timer-ellipse-no-image {
    right: 0 !important;
}

/* ------------------------------------------------------------ *\
	Item Menu Nested
\* ------------------------------------------------------------ */

.item-menu--nested {
    min-height: 97px;
    position: relative; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-pack: start;
        justify-content: flex-start;
    -ms-flex-align: start;
        align-items: flex-start;
    margin-bottom: 0;
    padding: 10px 0;
    border-bottom: 1px solid var(--border-color);
}

.item-menu--nested .item-menu__title { margin-bottom: 2px; font-family: var(--font-family-sans-serif); font-size: 14px; font-weight: 500; text-transform: none; color: var(--menu-item-color); }

.item-menu--nested .item-menu__image { max-width: 75px; height: 75px; -ms-flex: 0 0 75px; flex: 0 0 75px; margin-left: 10px; background-repeat: no-repeat; background-position: center center; background-size: cover; border-radius: 2px; }

[dir="rtl"] .item-menu--nested .item-menu__image { margin-left: 0px; margin-right: 10px; }

.item-menu--nested .item-menu__entry { margin-bottom: 15px; }

.item-menu--nested .item-menu__entry p.truncated { white-space: nowrap; overflow: hidden; -o-text-overflow: ellipsis; text-overflow: ellipsis; }

.item-menu--nested .item-menu__content { max-width: calc(100% - 85px); -ms-flex-positive: 1; flex-grow: 1; -ms-flex-negative: 1; flex-shrink: 1; -ms-flex-preferred-size: calc(100% - 85px); flex-basis: calc(100% - 85px); padding-top: 9px; }

.item-menu--nested .item-menu__content-full-width { max-width: 100%; -ms-flex-positive: 1; flex-grow: 1; -ms-flex-negative: 1; flex-shrink: 1; -ms-flex-preferred-size: calc(100% - 85px); flex-basis: calc(100% - 85px); padding-top: 9px; }

.item-menu--nested .item-menu__batch {
    position: absolute;
    right: calc(100% + 10px);
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background: var(--primary);
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
    color: var(--primaryButtonTextColor);
}

[dir="rtl"] .item-menu--nested .item-menu__batch { right:initial; left: calc(100% + 10px); }

.item-disabled {
    color: var(--unavailableGrey);
}

/* ------------------------------------------------------------ *\
	Sub Category
\* ------------------------------------------------------------ */

.sub-category { display: block; margin-top: 28px; font-size: 12px; line-height: 1.2; font-weight: 300; text-decoration: none; color: var(--lightGrey); cursor:pointer }

.sub-category:hover { text-decoration: none; }

.sub-category .sub-category__title { margin-bottom: 5px; font-family: var(--font-family-sans-serif-alt); text-transform: uppercase; color: var(--menu-subcategory-color); }

.sub-category .sub-category__label { display: -ms-inline-flexbox; display: inline-flex; -ms-flex-align: center; align-items: center; margin-left: 5px; font-size: 9px; font-weight: 700; text-transform: uppercase; }

.sub-category .sub-category__label span { margin-left: 2px; margin-top: 2px; }

.sub-category > .sub-category__head { padding-bottom: 28px; border-bottom: 1px solid var(--border-color); display: -ms-flexbox; display: flex; -ms-flex-pack: justify; justify-content: space-between; }

.sub-category .sub-category__icon { color: var(--menu-subcategory-color); font-size: 1.5em; padding-right: 10px; }

/* ------------------------------------------------------------ *\
	Loader
\* ------------------------------------------------------------ */

.loader { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: fixed;
}

.loader.light { background: #f0ebe7; }

.loader .loader__content { max-width: 100%; -ms-flex: 1 1 100%; flex: 1 1 100%; text-align: center; margin-bottom: 20px; }

.loader .loader__title { margin-bottom: 45px; font-size: 28px; font-weight: 400; color: var(--text-color); }

.circle-loader {
    -webkit-animation: rotateit 2s linear infinite;
    animation: rotateit 2s linear infinite;
    width: 10px;
    height: 10px;
    margin: 30px auto;
    position: relative;
}

.circle-loader div {
        height: 9px;
        width: 9px;
        background-color: var(--spinner-color);
        border-radius: 50%;
        position: absolute;
    }

.circle-loader > div:nth-child(1) {
        top: -20px;
        left: 0;
    }

.circle-loader > div:nth-child(2) {
        top: -14px;
        left: 14px;
    }

.circle-loader > div:nth-child(3) {
        top: 0;
        left: 20px;
    }

.circle-loader > div:nth-child(4) {
        top: 14px;
        left: 14px;
    }

.circle-loader > div:nth-child(5) {
        top: 20px;
        left: 0;
    }

.circle-loader > div:nth-child(6) {
        top: 14px;
        left: -14px;
    }

.circle-loader > div:nth-child(7) {
        top: 0;
        left: -20px;
    }

.circle-loader > div:nth-child(8) {
        top: -14px;
        left: -14px;
    }

@-webkit-keyframes rotateit {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes rotateit {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

/* ------------------------------------------------------------ *\
Loyalty Header
\* ------------------------------------------------------------ */

.loyalty-header { position:-webkit-sticky; position:sticky; height:40px; padding: 22px 0 0; top:0px; z-index: 40; background-color: var(--loyalty-header-color); padding-bottom: 40px;  color: black; }

.loyalty-header img {
        -webkit-filter: invert(100%) sepia(0%) saturate(7484%) hue-rotate(43deg) brightness(0%) contrast(99%);
                filter: invert(100%) sepia(0%) saturate(7484%) hue-rotate(43deg) brightness(0%) contrast(99%);
    }

.loyalty-header .loyalty-header__content { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
        -ms-flex-pack: justify;
            justify-content: space-between;
        -ms-flex-align: center;
            align-items: center;
        font-weight: 500;
    }

.loyalty-header .loyalty-header__message {   margin: auto; }

/* ------------------------------------------------------------ *\
	Mobile Number
\* ------------------------------------------------------------ */

.react-phone-number-input .react-phone-number-input__icon {
    border: none;
}

.react-phone-number-input .react-phone-number-input__input:focus
{
        border-color: #8f8f8f;
}

.react-tel-input .flag-dropdown { background-color:white; border:none; border-radius: 0px;}

.react-tel-input input[type=text], .react-tel-input input[type=tel] {
    width: 100% !important;
}

.country-list {
    text-align: left;
    width:280px !important;
}

/* ------------------------------------------------------------ *\
	Image
\* ------------------------------------------------------------ */

.modal-frame .modal-frame__centreimage {
    max-width: 75px;
    height: 75px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 2px;
    margin: 20px auto 15px;
}

.btn-combo {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

/* ------------------------------------------------------------ *\
	Modal
\* ------------------------------------------------------------ */

.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: start;
        align-items: flex-start;
    width: 100vw;
    max-width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    overflow: auto
}

.ReactModal__Content { width: 100%; max-width: 500px; min-width: 320px; }

/* ------------------------------------------------------------ *\
	Modal - Global Modifiers
\* ------------------------------------------------------------ */

.ReactModal__Body--open { overflow: hidden; }

.ReactModal__Body--open #root { -webkit-filter: blur(20px); filter: blur(20px); }

/* ------------------------------------------------------------ *\
	Modal Frame
\* ------------------------------------------------------------ */

.modal-frame { padding: 46px 15px 30px; }

.modal-frame .modal-frame__head { margin-bottom: 17px; text-align: center; }

.modal-frame .modal-frame__body { margin-bottom: 45px; }

.modal-frame .modal-frame__title { font-size: 14px; }

.modal-frame .modal-frame__sub-title { font-size: 16px; }

.modal-frame .modal-frame__actions .btn + .btn { margin-top: 10px; }

.modal-frame .modal-frame__actions--space { margin-top: 35px; margin-bottom: 35px }

.modal-frame .modal-frame__actions--flex { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center;
    }

.modal-frame .column-actions {
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-align: unset;
            align-items: unset;
    }

.modal-frame .column-actions .info {
        font-size: 12px;
    }

.modal-frame .column-actions .info-logo {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -ms-flex-pack: distribute;
            justify-content: space-around;
        margin-top: 20px;
        margin-bottom: 20px;
    }

.modal-frame .column-actions .action-selections {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }

.modal-frame .column-actions .donation-btn {
        margin-bottom: 7px;
        -ms-flex: 0 0 100% !important;
            flex: 0 0 100% !important;
        margin-left: 0 !important;
        text-transform: uppercase;
    }

.modal-frame .column-actions .single-donation {
        max-width: 100% !important;
    }

.modal-frame .column-actions .multi-donation {
        max-width: calc(50% - 5px) !important;
        margin-bottom: 10px;
    }

.modal-frame .column-actions .multi-donation:nth-child(odd) {
        margin-right: 10px;
    }

.modal-frame .column-actions .multi-line-btn {
        padding: 6px 5px;
        line-height: 1.4;
        font-size: 14px;
    }

.modal-frame .modal-frame__foot { text-align: center; }

.modal-frame .modal-frame__actions--flex .btn { max-width: 50%; -ms-flex: 0 0 50%; flex: 0 0 50%; }

.modal-frame .modal-frame__actions--flex .btn-auto {max-width: 55%; -ms-flex: auto; flex: auto}

.modal-frame .modal-frame__actions--flex .btn + .btn { margin-top: 0; margin-left: 5px; }

[dir="rtl"] .modal-frame .modal-frame__actions--flex .btn + .btn {margin-left: 0px; margin-right: 5px;}

/* ------------------------------------------------------------ *\
	Modal Frame Links
\* ------------------------------------------------------------ */

.modal-frame .modal-frame__links {
    margin-top: 30px;
    text-align: center;
    font-size: 12px;
}

.modal-frame .modal-frame__links a {
        color: var(--primary);
        font-weight: bold
    }

/* ------------------------------------------------------------ *\
	Modal Frame Alt
\* ------------------------------------------------------------ */

.modal-frame--alt { padding: 15px 15px 15px;  }

.modal-frame--alt .modal-frame__title {
    color: var(--modal-title-color);
}

.modal-frame .modal-frame__body--alt {
    margin-bottom: 15px;
}

/* ------------------------------------------------------------ *\
	Modal Frame Card
\* ------------------------------------------------------------ */

.modal-frame--card .modal-frame__card {
    padding: 20px 14px 20px 14px;
    border-radius: 6px;
    border: 1px solid #e5e5e5;
    background: var(--modal-background-color);
    color: var(--modal-text-color);
}

.modal-frame--card .modal-frame__title {
    color: var(--modal-title-color);
}

.modal-frame--card .modal-frame__card-field { margin-bottom: 30px; color:black; }

.modal-frame--card .modal-frame__card-field-preceder {
    margin-bottom: 30px;
    text-align:center;
}

.modal-frame--card .modal-frame__message {
    font-size:10px;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
}

.modal-frame--card .modal-frame__message a {
        color: var(--primary);
        text-decoration: underline;
    }

.modal-frame--card .modal-frame__card-field-preceder .field {
    text-align: center;
}

.modal-frame--card .modal-frame__card-field .field { text-align: center; }

.modal-frame--card .modal-frame__card-group { margin-bottom: 25px; }

.modal-frame--card .modal-frame__card-group--alt { margin-bottom: 20px; }

.modal-frame--card .modal-frame__card-entry { margin-bottom: 20px; font-size: 10px; font-weight: 300; line-height: 2.2; text-align: center; }

.modal-frame--card .modal-frame__actions { text-align: center; }

/* ------------------------------------------------------------ *\
	Modal Frame Loyalty
\* ------------------------------------------------------------ */

.modal-frame--loyalty .modal-frame__loyalty {
    padding: 20px 14px 20px 14px;
    border-radius: 6px;
    border: 1px solid var(--border-color);
    background: var(--modal-loyalty-background-color);
    color: var(--modal-loyalty-text-color);
}

.modal-frame--loyalty .modal-frame__head h3 {
    color: var(--modal-loyalty-title-color);
}

.modal-frame--loyalty .modal-frame__card-body {
    color:black;
}

.modal-frame--loyalty .modal-frame__head h6 {
    font-weight: normal;
}

.modal-frame--loyalty .modal-frame__card-field {
    margin-bottom: 30px;
    color: black;
}

.modal-frame--loyalty .modal-frame__card-field-preceder {
    margin-bottom: 30px;
    text-align: center;
}

.modal-frame--loyalty .modal-frame__message {
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
}

.modal-frame--loyalty .modal-frame__message a {
    color: var(--primary);
    text-decoration: underline;
}

.modal-frame--loyalty .modal-frame__card-field-preceder .field {
    text-align: center;
}

.modal-frame--loyalty .modal-frame__card-field .field {
    text-align: center;
}

.modal-frame--loyalty .modal-frame__loyalty-group {
    margin-bottom: 25px;
}

.modal-frame--loyalty .modal-frame__loyalty-group--alt {
    margin-bottom: 20px;
}

.modal-frame--loyalty .modal-frame__loyalty-entry {
    margin-bottom: 20px;
    font-size: 10px;
    font-weight: 300;
    line-height: 2.2;
    text-align: center;
}

.modal-frame--loyalty .modal-frame__actions {
    text-align: center;
}

.modal-frame--loyalty .modal-frame__links a {
    color: var(--modal-loyalty-title-color);
    font-weight: bold
}

/* ------------------------------------------------------------ *\
	Modal Frame Warning
\* ------------------------------------------------------------ */

.modal-frame--warning .modal-frame__head { margin-bottom: 40px; font-size: 16px; }

.modal-frame--warning .modal-frame__card-icon { margin-bottom: 15px; font-size: 20px; color: #ffcf00; }

/* ------------------------------------------------------------ *\
	Modal Frame Allergens
\* ------------------------------------------------------------ */

.modal-frame--allergens .modal-frame__head {
    margin-bottom: 20px;
    font-size: 14px;
    text-align:left;
}

.modal-frame--allergens .modal-frame__title {
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    /*font-weight: bold;*/
}

.btn-yes {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 25px;
    padding-right: 25px;
}

.over-18 {
    font-size: 10px;
}

/* ------------------------------------------------------------ *\
	Modal Frame Receipt
\* ------------------------------------------------------------ */

.modal-frame--card .modal-frame__receipt
{
    min-height: 210px;
}

.modal-frame .modal-frame__head-receipt {
    margin-top: 75px;
}

/* ------------------------------------------------------------ *\
	Icons
\* ------------------------------------------------------------ */

.modal-frame .fa-check-circle {
    color: var(--primary);
}

.modal-frame .fa-exclamation-triangle {
    color: #FFC902;
}

/* ------------------------------------------------------------ *\
	Validation Error
\* ------------------------------------------------------------ */

.modal-frame .modal-frame__card-error {
    text-align: center;
    color:red;
}

/* ------------------------------------------------------------ *\
	Centre
\* ------------------------------------------------------------ */

.modal-frame--center {
    text-align: center
}

/* ------------------------------------------------------------ *\
	Navigation
\* ------------------------------------------------------------ */

.modal-frame .modal-frame__navigation {
    background-color: #F3F3F3;
    border-radius: 10px;
    width: 100px;
    margin: auto;
    margin-bottom: calc(var(--line-height-base-em));
    padding: 2px 7px 2px 7px;
    text-align: center;
    font-size: 12px;
}

.modal-frame .modal-frame__navigation i {
    color: var(--primary);
}

/* ------------------------------------------------------------ *\
	Image
\* ------------------------------------------------------------ */

.modal-frame .modal-frame__image {
    max-width: 75px;
    height: 75px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 2px;
    margin: 20px auto 15px;
}

.modal-frame ul li {
    margin-left: 20px;
}

/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav { font-family: var(--font-family-sans-serif-alt); font-size: 11px; font-weight: 500; text-transform: uppercase; white-space: nowrap; color: var(--grey); }

.nav ul {
        list-style-type: none; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        -ms-flex-pack: start;
            justify-content: flex-start;
        -ms-flex-align: center;
            align-items: center;
        overflow-x: auto;
        padding-bottom: 10px;
    }

.nav li + li { margin-left: 5px; }

.nav a { display: block; padding: 6px 11px; border-radius: 2px; border: 1px solid #dfdfdf; text-decoration: none; -webkit-transition: background .3s, color .3s; -o-transition: background .3s, color .3s; transition: background .3s, color .3s; }

.nav a.current {
        background: var(--primary);
        color: var(--primaryButtonTextColor);
    }

/* ------------------------------------------------------------ *\
	Nets
\* ------------------------------------------------------------ */

.nets-dropin {

}

.nets-dropin iframe{
    width: 100%;
}

.nets-dropin .nets-icon--bordered {
    background: #FFFFFF;
    border: 1px solid #BBBBBB;
    border-radius: 3px;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
}

.nets-dropin .nets-options-list {
    margin: 0 auto;
    padding-bottom: 10px;
    width: 100%;
}

.nets-dropin .nets-option:first-child {
    border-radius: 4px 4px 0 0;
    border-top-width: 1px;
}

.nets-dropin .nets-option:last-child {
    border-radius: 0 0 4px 4px;
}

.nets-dropin .nets-option {
    -ms-flex-align: center;
        align-items: center;
    background-color: #FAFAFA;
    border-color: #B5B5B5;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    padding: 12px 10px;
}

.nets-dropin .nets-option .nets-option__label {
    color: #000000;
    font-size: 16px;
    margin-left: 20px;
    width: 100%;
}

.nets-dropin .nets-option .nets-option__logo {
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    -ms-flex-align: center;
        align-items: center;
}

.nets-dropin .nets-option .nets-option__logo-svg {
   width: 48px;
   height: 29px;
}

.nets-dropin .nets-heading {
    color: #000000;
    font-size: 16px;
    margin-bottom: 10px;
}

.nets-dropin .nets-large-button {
    background: #FAFAFA;
    border-radius: 4px;
    color: #000000;
    cursor: pointer;
    font-size: 14px;
    margin: 0 auto;
    padding: 18px;
    text-align: center;
    width: 100%;
    text-decoration: underline;
}

.nets-dropin .nets-sheet {
    background-color: white;
    border: 1px solid #B5B5B5;
    border-radius: 4px;
    margin: 0 auto;
    max-height: 700px;
    -webkit-transition: opacity 300ms, max-height 300ms ease, -webkit-transform 300ms;
    transition: opacity 300ms, max-height 300ms ease, -webkit-transform 300ms;
    -o-transition: transform 300ms, opacity 300ms, max-height 300ms ease;
    transition: transform 300ms, opacity 300ms, max-height 300ms ease;
    transition: transform 300ms, opacity 300ms, max-height 300ms ease, -webkit-transform 300ms;
    width: 100%;
    margin-bottom: 10px;
}

.nets-dropin .nets-sheet__header {
    -ms-flex-align: center;
        align-items: center;
    border-bottom: 1px solid #B5B5B5;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 12px 15px 0 12px;
    position: relative;
}

.nets-dropin .nets-sheet__header .nets-sheet__header-label {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 1;
        flex-grow: 1;
    padding-bottom: 12px;
}

.nets-dropin .nets-sheet__header .nets-sheet__logo--header {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    width: 50px;
}

.nets-dropin .nets-sheet__header .nets-sheet__label {
    font-size: 16px;
    margin-left: 20px;
}

.nets-dropin .nets-sheet__header .nets-option__logo-svg {
    width: 48px;
    height: 29px;
}

.nets-dropin .nets-sheet__content--form {
    padding: 10px 15px 10px 10px;
}

.nets-dropin .nets-sheet__header .nets-sheet__icons {
    padding-bottom: 10px;
}

.nets-dropin .nets-sheet__header .nets-sheet__card-icon {
    display: inline-block;
    padding-left: 5px;
}

/* ------------------------------------------------------------ *\
	New Tab
\* ------------------------------------------------------------ */

.new-tab { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex: 1 1;
        flex: 1 1;
    -ms-flex-direction: column;
        flex-direction: column;
}

.new-tab .new-tab__head { padding: 22px 0 0; font-size: 12px; font-weight: 500; text-align: center; color:var(--header-text-color); background-color:var(--header-background-color);}

.new-tab .new-tab__head-inner { position: relative; margin-bottom: 22px; padding: 0 30px; }

.new-tab .new-tab__head-btn { position: absolute; left: 0; top: 0px; padding: 10px 10px 10px 6px; background: none; border: none; font-size: 17px; color: var(--header-btn-color); }

[dir="rtl"] .new-tab .new-tab__head-btn { left:initial; right: 0;}

.new-tab .new-tab__hr { border-top: 1px solid var(--border-color); padding-bottom: 25px; }

.new-tab .new-tab__body {-ms-flex: 1 1;flex: 1 1}

.new-tab .new-tab__logo { display: block; margin-left: auto; margin-right: auto; width: 160px; }

.new-tab .new-tab__logo svg { width: 160px; height: 160px; }

.new-tab .new-tab__title { font-family: var(--font-family-sans-serif-alt); font-size: 16px; font-weight: 500; text-transform: uppercase; }

.new-tab .new-tab__actions .btn + .btn { margin-top: 10px; }

.new-tab .new-tab__section { margin-bottom: 16px; }

.new-tab .new-tab__section--alt { margin-bottom: 30px; }

.new-tab .new-tab__section--modified { margin-bottom: 50px; }

.new-tab .new-tab__section-inner { padding: 0 25px; }

.new-tab .new-tab__section-actions { margin-top: 11px; }

.new-tab .new-tab__section-title { margin-bottom: 9px; font-size: 14px; }

.new-tab .new-tab__information { padding-left: 35px; padding-right: 35px; max-width: 750px; margin-left: auto; margin-right: auto; display: block;}

.new-tab .new-tab__information li { margin: 10px 0;}

.new-tab .new-tab__foot { position: -webkit-sticky; position: sticky; bottom: 0; left: 0; z-index: 30; padding: 10px 0; background: var(--background-color); }

/* ------------------------------------------------------------ *\
	Payment
\* ------------------------------------------------------------ */

.payment { display: none; overflow:scroll; position: fixed; left: 0; top: 0; z-index: 100; width: 100%; height: 100%; background: var(--background-color); -webkit-transition: -webkit-transform .3s; transition: -webkit-transform .3s; -o-transition: transform .3s; transition: transform .3s; transition: transform .3s, -webkit-transform .3s; -webkit-transform: translateY(100%); -ms-transform: translateY(100%); transform: translateY(100%); }

.payment.visible { display: inline; -webkit-transform: translateY(0%); -ms-transform: translateY(0%); transform: translateY(0%); }

.payment .payment__inner { padding: 0px 0; }

.payment .payment__total { text-align: center; font-size: 18px; font-weight: bold; margin-top: 5px; margin-bottom: 5px; color: var(--payment-total-color); }

.payment .payment__error { text-align: center; font-size: 12px; font-weight: 500; color: #AD431D; background-color: #FFCCB9; border-radius: 5px; padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-top: 20px; 
}

.payment .payment__info {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: black;
    background-color: var(--day-part-banner-bg-color);
    border-radius: 5px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.payment-secure {
    margin-top: 10px;
    font-size: 12px;
}

.payment-message {
    margin-top: 10px;
    font-size: 12px;
}

.payment .payment__body {

}

.payment .payment__head {
    color: var(--header-text-color);
    padding: 22px 0 0;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    background-color: var(--header-background-color);
    height: 68px;
}

.payment .payment__head-inner {
    position: relative;
    margin-bottom: 22px;
    padding: 0 30px;
}

.payment .payment__head-btn-container {
    position: absolute;
    left: 0;
    top: 0px;
    padding: 10px 10px 10px 6px;
    background: none;
    border: none;
    font-size: 17px;
}

[dir="rtl"] .payment .payment__head-btn-container {
    left: initial;
    right: 0;
}

.payment .payment__head-btn {
    padding-right: 12px;
    color: var(--header-btn-color);
}

.payment .payment__title {
    font-family: var(--font-family-sans-serif-alt);
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
}

.braintree-dropin-submit-btn-wrapper{
    margin-top: 20px;
}

.freedom-pay-sheet__content--form input {
    width: 100%;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    color: #495057;
    display: block;
    font-size: 1rem;
    height: calc(2.25rem + 2px);
    line-height: 1.5;
    padding: .375rem .75rem;
    /*padding-left: calc(.75rem + 31px);*/
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.freedom-pay-sheet__content--form .control-group {
    /*margin-bottom: 1em;*/
    position: relative;
    font: 16px Arial,sans-serif;
    padding: 10px;
    padding-bottom: 0;
}

.freedom-pay-sheet__content--form .control-group label {
        display: inline-block;
        margin-bottom: .5rem;
    }

.adyen-payment-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
}

/* ------------------------------------------------------------ *\
	Product
\* ------------------------------------------------------------ */

.product { position: relative; height: 300px; background-position: center center; background-repeat: no-repeat; background-size: cover; }

.product .product__content {
		position: absolute;
		bottom: 20px;
		right: 0;
		width: 180px;
		padding: 5px 10px;
		border-radius: 2px 0 0 2px;
		background: var(--primary);
		font-size: 11px;
		line-height: 1.1;
		text-transform: uppercase;
		text-align: left;
		color: var(--primaryButtonTextColor);
	}

/* ------------------------------------------------------------ *\
	Product Single
\* ------------------------------------------------------------ */

.product-single { font-size: 12px; line-height: 1.1; font-weight: 300; }

.product-single .product-single__image { position: relative; padding-bottom: 56.25%; height: 0; background-repeat: no-repeat; background-position: center center; background-size: cover; }

.product-single .product-single__btn { padding:10px; position: absolute; left: 16px; top: 26px; background: none; border: none; font-size: 16px; color: var(--text-color); }

[dir="rtl"] .product-single .product-single__btn { left: initial; right: 16px;}

.product-single .product-single__title { margin-bottom: 8px; font-size: 14px; font-weight: 500; color: var(--product-question); }

.product-single .product-single__title--main {color: var(--product-title);}

.product-single .product-single__title--alt { margin-bottom: 11px; padding: 0 15px; }

.product-single .product-single__title--alt span { color: var(--danger); }

.product-single .product-single__group { margin-bottom: 26px; }

.product-single .product-single__content { padding: 20px 0 16px; }

.product-single .product-single__holder .invalid__item { display: -ms-flexbox; display: flex; -ms-flex-direction: column; flex-direction: column; -ms-flex-pack: center; justify-content: center; -ms-flex-align: center; align-items: center; }

.product-single .product-single__holder .invalid__item .invalid__item_msg { font-weight: 700; font-size: 16px; text-align: center; }

.product-single .product-single__holder { padding: 17px 0; }

.product-single .product-single__section--alt { margin-top: 60px; padding: 0 15px; }

.product-single .product-single__qty { padding: 15px 0; border-width: 1px 0; border-style: solid; border-color:var(--border-color); background-color: var(--product-item-background-color) }

.product-single .product-single__entry { margin-bottom: 10px; }

.product-single .product-single__entry:last-child { margin-bottom: 0; }

/* ------------------------------------------------------------ *\
Receipt
\* ------------------------------------------------------------ */

.receipt { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex: 1 1;
        flex: 1 1;
    -ms-flex-direction: column;
        flex-direction: column;
}

.receipt .receipt__head { padding: 22px 0 0; font-size: 12px; font-weight: 500; text-align: center; color:var(--header-text-color); background-color:var(--header-background-color); }

.receipt .receipt__head-inner { position: relative; margin-bottom: 22px; padding: 0 30px; }

.receipt .receipt__head-btn { position: absolute; left: 0; top: 0px; padding: 10px 10px 10px 6px; background: none; border: none; font-size: 17px; color: var(--header-btn-color); }

[dir="rtl"] .receipt .receipt__head-btn { left: initial; right: 0; }

.receipt .receipt__hr { border-top: 1px solid var(--border-color); padding-bottom: 25px; }

.receipt .receipt__title { font-family: var(--font-family-sans-serif-alt); font-size: 16px; font-weight: 500; text-transform: uppercase; }

.receipt .receipt__body {-ms-flex: 1 1;flex: 1 1}

.receipt .receipt__actions .btn + .btn { margin-top: 10px; }

.receipt .receipt__section { margin-bottom: 16px; }

.receipt .receipt__section--alt { margin-bottom: 30px; }

.receipt .receipt__section--modified { margin-bottom: 50px; }

.receipt .receipt__section-inner { padding: 0 25px; }

.receipt .receipt__section-actions { text-align: center; }

.receipt .receipt__section-actions .btn + .btn { margin-top: 10px; }

.receipt .receipt__section-actions--space { margin-top: 35px; margin-bottom: 35px }

.receipt .receipt__section-actions--flex { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center;
    }

.receipt .receipt__section-actions--flex .btn { max-width: 50%; -ms-flex: 0 0 50%; flex: 0 0 50%; }

.receipt .receipt__section-actions--flex .btn + .btn { margin-top: 0; margin-left: 5px; }

[dir="rtl"] .receipt .receipt__section-actions--flex .btn + .btn { margin-left: initial; margin-right: 5px;}

.receipt .receipt__section-text { margin-bottom: 17px; text-align: center; }

.receipt .receipt__section-title {
    margin-bottom: 9px;
    font-size: 14px;
}

.receipt .receipt__foot { position: -webkit-sticky; position: sticky; bottom: 0; left: 0; z-index: 30; padding: 10px 0; background: var(--background-color); }

/* ------------------------------------------------------------ *\
	Search
\* ------------------------------------------------------------ */

.search { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex: 1 1;
        flex: 1 1;
    -ms-flex-direction: column;
        flex-direction: column;
}

.search .search__head {  color:var(--header-text-color); padding: 22px 0 0; font-size: 12px; font-weight: 500; text-align: center; background-color:var(--header-background-color);}

.search .search__hr { border-top: 1px solid rgb(225, 225, 225); padding-bottom: 25px; }

.search .search__head-inner { position: relative; margin-bottom: 22px; padding: 0 30px; }

.search .search__head-btn { padding-right: 12px; color: var(--header-btn-color); }

.search .search__head-btn-container { position: absolute; left: 0; top: 0px; padding: 10px 10px 10px 6px; background: none; border: none; font-size: 17px;}

[dir="rtl"] .search .search__head-btn-container { left: initial; right: 0;}

.search .search__body {-ms-flex: 1 1;flex: 1 1}

.search .search__container {padding-left: 30px;}

.search .search__input-wrapper { position: relative; margin-left: 20px; margin-right: 20px; }

.search .search__input-icon { color: var(--header-btn-color); font-size: 17px; font-weight: 900; padding-right: 0.5em; position: absolute; top: 6px; right: 0; }

.search .search__input { width: 100%; background-color: #F5F5F5; padding-top:6px; padding-bottom:6px; border-radius: 2px; border: none; padding-left:20px; padding-right:30px; font-size: 14px;   }

/* ------------------------------------------------------------ *\
	Section Categories
\* ------------------------------------------------------------ */

.section-categories { padding: 15px 0; }

.section-categories .btn-section { display: -ms-flexbox; display: flex; -ms-flex-pack: center; justify-content: center; margin-bottom: 18px; text-transform: uppercase; }

/* ------------------------------------------------------------ *\
	Section Menu
\* ------------------------------------------------------------ */

.section-menu .section__head { position: -webkit-sticky; position: sticky; top: 67px; z-index: 30; margin-bottom: 4px; padding: 10px 0; background: var(--background-color); background-size:cover; border-bottom: 1px solid var(--border-color); }

.section-menu .section__body { background: var(--menu-background); }

.section-menu .section__link { display: block; padding: 6px 11px; border-radius: 2px; border: 1px solid var(--border-color); text-decoration: none; -webkit-transition: background .3s, color .3s; -o-transition: background .3s, color .3s; transition: background .3s, color .3s; background: var(--category-background); }

.section-menu .section__link.current { background: var(--selected-category-background); color: var(--selected-category-text-color); font-weight: 500; }

.section-menu .section__foot { position: -webkit-sticky; position: sticky; bottom: 0; z-index: 30; padding: 10px 0; background: var(--background-color); }

.section-menu .section__foot__alt { position: -webkit-sticky; position: sticky; bottom: 0; z-index: 30; padding: 10px 0; }

.section-menu .section__foot span.label {
    margin-left: 10px;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background: var(--primaryButtonTextColor);
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
    color: var(--primary);
}

.section-menu .section__foot__alt span.label {
    margin-left: 10px;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background: var(--primaryButtonTextColor);
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
    color: var(--primary);
}

.section-menu .section__foot span > span { display: inline-block; vertical-align: middle; }

.section-menu .section__foot__alt span > span { display: inline-block; vertical-align: middle; }

.section-menu .section__categories { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        -ms-flex-pack: start;
            justify-content: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
        padding-bottom: 10px;
        margin-bottom: -10px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

.section-menu .section__categories a { -ms-flex: 0 0 auto; flex: 0 0 auto; margin: 0 2px; }

/* ------------------------------------------------------------ *\
	Select Language
\* ------------------------------------------------------------ */

.select-language {
    direction: ltr;
}

.select-language, .select-language a {
    text-align: left;
    color: var(--select-language-color);
    text-decoration: none;
}

.select-language .select-language_icon { display: inline-block;  }

#nav\/language\/color { fill: var(--select-language-color); }

.header #nav\/language\/color { fill: var(--header-btn-color); }

.select-language .select-language_description {
    padding-left: 10px;
}

[dir="rtl"] .select-language {
    float: right;
}

[dir="rtl"] .header__content .select-language {
    float: initial;
}

/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */

.shell { max-width: var(--shell-max-width); padding-left: var(--shell-gutter); padding-right: var(--shell-gutter); margin: auto; }

.shell-suggestions { max-width: 512px; }

.shell--no-padding { padding: 0; }

/* ------------------------------------------------------------ *\
	Shell Fluid
\* ------------------------------------------------------------ */

.shell-fluid { max-width: none; }

/* ------------------------------------------------------------ *\
	Shell Alt
\* ------------------------------------------------------------ */

.shell--alt { max-width: var(--shell-max-width); padding-left: var(--shell-gutter-small); padding-right: var(--shell-gutter-small); }

/* ------------------------------------------------------------ *\
	Slider Products
\* ------------------------------------------------------------ */

.slider-products { max-width: 500px; margin: 0 auto 30px; }

.slider-products .slick-list { }

.slider-products .slick-slide { padding: 0 5px; }

.slider-products .slider__slide { vertical-align: top; }

.slider-products .slick-dots { display: -ms-flexbox !important; display: flex !important; -ms-flex-pack: center; justify-content: center; -ms-flex-align: center; align-items: center; bottom: -20px; }

.slider-products .slick-dots li { width: auto; height: auto; margin: 0 4px; }

.slider-products .slick-dots li.slick-active button { background: var(--black); }

.slider-products .slick-dots li button { padding: 0; width: 8px; height: 8px; border-radius: 50%; background: rgba(0, 0, 0, .4); -webkit-transition: background .3s; -o-transition: background .3s; transition: background .3s; }

.slider-products .slick-dots li button:before { display: none; }

/* ------------------------------------------------------------ *\
	Slider Menu
\* ------------------------------------------------------------ */

.slider-menu .slick-list { margin: 0 -5px; }

.slider-menu .slick-slide { padding: 0 5px; }

.sommelier-home-container .sommelier-selections .btn-item { margin-top: 20px; }

.menu-link-item { margin: 20px 0 20px 0px; text-transform: uppercase; }

.menu-link-item:hover { cursor: pointer; }

.sommelier-choose-container .cancel-quiz {position: fixed; width: 100%; bottom: 0; z-index: 30; height: 72px; background: rgba(77, 59, 46, 0.5); display: -ms-flexbox; display: flex; -ms-flex-pack: center; justify-content: center; -ms-flex-align: center; align-items: center;}

.sommelier-choose-container .cancel-quiz:hover { cursor: pointer; }

.sommelier-about-container .sommelier-info { 
    position: relative;
    background-color: var(--teeling-brand-color); 
    margin-top: 100px; 
    font-size: 12px;
    font-weight: 400;
    color: var(--black);
}

.sommelier-about-container .sommelier-info .teeling-badge {
    position: absolute;
    top: -60px;
    left: 0;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
}

.sommelier-about-container .sommelier-info .sommelier-section {
    padding: 10px 30px;
}

.sommelier-about-container .sommelier-info .initial-section {
    padding-top: 88px;
}

.sommelier-about-container .sommelier-info .sommelier-section div:nth-child(2) {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    margin-top: 20px;
}

.teeling-logo-small {
    height: 60px;
}

.split-container { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex: 1 1;
        flex: 1 1;
    -ms-flex-direction: column;
        flex-direction: column;
}

.split-info-container { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex: 1 1;
        flex: 1 1;
    -ms-flex-direction: column;
        flex-direction: column;
}

.split-info-container .invite-header { position: -webkit-sticky; position: sticky; height:40px; padding: 22px 0 0; top:0px; z-index: 40; background-color: var(--tab-open-color); padding-bottom: 40px; -webkit-box-shadow: 3px 1px 10px 6px #cccccc75; box-shadow: 3px 1px 10px 6px #cccccc75; }

.split-info-container .invite-header .invite-header-content { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
    font-weight: 500;
    font-size: 12px;
    color: black;
}

.split-info-container .invite-header .invite-header-content .invite-link { margin-right:11px; position: relative; }

.split-info-container .split-info-head { padding: 22px 0 0; font-size: 12px; font-weight: 500; text-align: center; color:var(--header-text-color); background-color:var(--header-background-color);}

.split-info-container .split-info-head .split-info-head-back { position: relative; margin-bottom: 22px; padding: 0 30px; }

.split-info-container .split-info-head .split-info-head-back .split-info-head-back-btn { position: absolute; left: 0; top: 0px; padding: 10px 10px 10px 6px; background: none; border: none; font-size: 17px; color: var(--header-btn-color); }

[dir="rtl"] .split-info-container .split-info-head .split-info-head-back .split-info-head-back-btn { left:initial; right: 0;}

.split-info-container .split-info-head .split-info-head-back .split-info-head-back-title { font-family: var(--font-family-sans-serif-alt); font-size: 16px; font-weight: 500; text-transform: uppercase; }

.split-info-container .split-info-body {display: -ms-flexbox;display: flex; -ms-flex-direction: column; flex-direction: column; -ms-flex: 1 1; flex: 1 1;}

.split-info-container .split-info-body .split-info-body-hr { border-top: 1px solid var(--border-color); padding-bottom: 25px; }

.split-update-info-msg { 
    color: #059;
    background-color: #BEF;
    margin-bottom: 10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    height: 60px;
 }

.add-items-split-msg {
    color: #000000;
    height: 78px;
    border-radius: 5px;
 }

.split-info-container .split-info-body .split-info-amount {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
    text-align: center;
    font-size: 16px;
    padding-bottom: 10px;
    margin: 0 15%;
}

.split-info-container .split-info-body .split-info-portion {
    font-weight: 700;
    /* border-bottom: 1px solid #E3E3E3; */
    padding-bottom: 20px;
}

.split-info-container .split-info-body .split-info-details {
    color: var(--primary);
    font-size: 12px;
    font-weight: 700;
    padding-bottom: 30px;
    display: block;
    cursor: pointer;
}

.small-info-icon {
    border: solid 1px var(--primary);
    border-radius: 20px;
    padding: 1px 5px;
}

.split-info-container .split-info-body .split-info-tip {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    font-size: 16px;
    font-weight: 700;
}

.split-info-container .split-info-body .split-info-tip .split-info-tip-label {
    padding: 30px 0 20px 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
}

.split-info-container .split-info-body .split-info-tip .split-info-tip-select {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
}

.split-info-container .split-info-body .split-info-tip .split-info-tip-select .tip-selection {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    font-size: 12px;
    font-weight: 400;
    width: 72px;
    height: 48px;
    border: 1px solid #E3E3E3;
    border-radius: 80px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.split-info-container .split-info-body .split-info-tip .split-info-tip-select .tip-selection .tip-label {
    font-weight: 700;
}

.split-info-container .split-info-body .split-info-tip .split-info-tip-select .tip-selection .custom-tip-amount input[type='text'] {
    border: 0;
    font-size: 16px;
    width: 100%;
    text-align: center;
}

.split-info-container .split-info-body .split-info-tip .split-info-tip-select .large-selection {
    width: 318px;
    height: 58px;
}

.selected-tip-selection {
    border: 1px solid var(--primary);
    background: var(--primary);
    color: #FFFFFF;
}

.split-info-container .split-info-body .split-info-tip .split-info-tip-percents .final-tip-selection {
    margin-right: 0;
}

.split-info-container .split-info-body .split-amount-selection-container {
    background-color: #F3F3F3;
    border-radius: 20px;
    height: 197px;
    margin: 15px 38px;
}

.split-info-container .split-info-body .split-amount-selection-container .split-amount-input {
    padding: 8px 0 0 8px;
}

.split-info-container .split-info-body .split-amount-or-divider {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    font-size: 14px;
    color: #ADADAD;
    margin: 15px 38px;
}

.split-info-container .split-amount-foot {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 30;
    padding: 10px 0;
    background: var(--background-color);
}

/* .split-info-container .split-amount-foot .foot-actions {

} */

.split-info-container input[type='radio']:checked + label, .split-info-container input[type="radio"]:not(:checked) + label {
    /* position: absolute;
    left: 45px;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: var(--header-text-color);
    font-weight: 700; */
}

.split-info-container input[type='radio']:checked + label:before, .split-info-container input[type='radio']:not(:checked) + label:before {
    /* content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff; */
}

.split-info-container input[type='radio']:checked + label:after, .split-info-container input[type='radio']:not(:checked) + label:after {
    /* content: '';
    width: 18px;
    height: 18px;
    background: #53BEFE;
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; */
}

.split-info-container input[type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
        transform: scale(0);
}

.split-info-container input[type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
        transform: scale(1);
}

.split-info-container .split-amount-info {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    padding: 0 10px;
}

.split-info-container .without-select {
    margin-top: 20px
}

.split-info-container .split-amount-info .title {
    font-size: 16px;
    font-weight: 700;
}

.split-info-container .msg {
    font-size: 12px;
    font-weight: 400;
}

.split-info-container .balance-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center;
    margin-bottom: 10px;
}

.split-amount-spacer {
    margin-top: 20px;
}

.split-info-container .split-amount-custom {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    background-color: #FFFFFF;
    color: #000000;
    height: 60px;
    border-radius: 10px;
    margin: 0 20px;
}

.split-info-container .split-amount-custom input[type='text'] {
    border: 0;
    font-size: 36px;
    width: 100%;
    text-align: center;
}

.split-info-container .split-amount-even-count {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    background-color: #FFFFFF;
    color: #A4A4A4;
    height: 70px;
    border-radius: 95px;
    margin: 0 42px;
}

.split-info-container .split-amount-even-count .split-amount-counter {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    padding: 0 30px;
    color: #000000;
}

.split-info-container .split-amount-even-count .split-amount-counter .value {
    font-size: 36px;
}

.split-info-container .split-amount-even-count .split-amount-counter .label {
    font-size: 12px;
}

.ReactModal__Overlay {
    opacity: 0;
    -webkit-transition: opacity 1000ms ease-in-out;
    -o-transition: opacity 1000ms ease-in-out;
    transition: opacity 1000ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

.amount-info {
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 20px;
    font-size: 14px;
}

.amount-info .title {
    font-size: 16px;
    font-weight: 700;
}

.amount-close {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    margin-top: 12px;
    font-size: 14px;
    font-weight: 700;
}

.qr-alt-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    font-size: 12px;
    font-weight: 400;
    margin-top: 14px;
}

.qr-alt-container .alternatives {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-top: 14px;
    -ms-flex-pack: center;
        justify-content: center;
}

.qr-alt-container .alternatives .item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    height: 30px;
    width: 80px;
    margin: 0 5px 5px 0;
    font-size: 10px;
}

.qr-alt-container .alternatives .item .icon {
    margin-right: 3px;
}

.left-arrow {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: 20px;
    width: 20px;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    -webkit-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
            transform: rotate(135deg);
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 0px;
    color: #EC0180;
}

.left-arrow:hover {
    cursor: pointer;
}

.right-arrow {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: 20px;
    width: 20px;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 0px;
    color: #EC0180;
}

.right-arrow:hover {
    cursor: pointer;
}

div[disabled] {
    pointer-events: none;
    color: #A4A4A4;
}

input[type='text']:disabled {
    background-color: #FFFFFF;
}

/* ------------------------------------------------------------ *\
	Split Checkout
\* ------------------------------------------------------------ */

.split-checkout-container {  background: var(--checkout-background-color); font-size: 14px; line-height: 1.43; font-weight: 300; text-align: center; -ms-flex: 1 1; flex: 1 1; }

.split-checkout-container .split-checkout-container__inner { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center;
        min-height: 100%;
        max-width: 324px;
        margin: 0 auto;
        padding: 20px 0;
    }

.split-checkout-container .split-checkout-container__logo { margin-bottom: 25px; }

.split-checkout-container .split-checkout-container__title { font-size: 20px; color: var(--checkout-title) }

.split-checkout-container .split-checkout-container__table { font-size: 16px; margin-bottom: 25px; }

.split-checkout-container .split-checkout-container__content { margin-bottom: 30px; }

.split-checkout-container .split-checkout-container__balance { margin-bottom: 30px; }

.split-checkout-container .split-checkout-container__actions { width: 100%; max-width: 205px; margin: 0 auto; }

.split-checkout-container .split-checkout-container__actions .btn + .btn { margin-top: 10px; }

/* ------------------------------------------------------------ *\
	Split Checkout Header
\* ------------------------------------------------------------ */

.split-checkout-container .split-checkout-container__header {
        height: 40px;
        padding: 22px 0 0;
        top: 0px;
        z-index: 40;
        background-color: var(--tab-open-color);
        padding-bottom: 40px;
        -webkit-box-shadow: 3px 1px 10px 6px #cccccc75;
                box-shadow: 3px 1px 10px 6px #cccccc75;
    }

.split-checkout-container .split-checkout-container__header-content { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
        -ms-flex-pack: justify;
            justify-content: space-between;
        -ms-flex-align: center;
            align-items: center;
        font-weight: 500;
        color: black;
    }

.split-checkout-container .split-checkout-container__header-balance { }

.split-checkout-container .split-checkout-container__header-pay { 
        margin-right: 11px;
        position: relative;
}

.split-error-container {  background: var(--checkout-background-color); font-size: 14px; line-height: 1.43; font-weight: 300; text-align: center; -ms-flex: 1 1; flex: 1 1; }

.split-error-container .split-error-container__inner { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    min-height: 100%;
    max-width: 324px;
    margin: 0 auto;
    padding: 20px 0;
}

.split-error-container .split-error-container__logo {
    margin-bottom: 25px;
}

.split-error-container .split-error-container__content {
      margin-bottom:50px;
}

.split-error-container .split-error-container__title {
    font-size: 15px;
    color: var(--checkout-title);
    margin-bottom:10px;
}

.split-error-container .split-error-container__message {
    font-size: 15px;
}

.split-error-container .split-error-container__actions { width: 100%; max-width: 205px; margin: 0 auto; }

.split-error-container .split-error-container__actions .btn + .btn { margin-top: 10px; }

/* ------------------------------------------------------------ *\
	Spreedly
\* ------------------------------------------------------------ */

.spreedly-dropin {

}

.spreedly-dropin iframe{
    width: 100%;
}

.spreedly-dropin .spreedly-icon--bordered {
    background: #FFFFFF;
    border: 1px solid #BBBBBB;
    border-radius: 3px;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
}

.spreedly-dropin .spreedly-options-list {
    margin: 0 auto;
    padding-bottom: 10px;
    width: 100%;
}

.spreedly-dropin .spreedly-option:first-child {
    border-radius: 4px 4px 0 0;
    border-top-width: 1px;
}

.spreedly-dropin .spreedly-option:last-child {
    border-radius: 0 0 4px 4px;
}

.spreedly-dropin .spreedly-option {
    -ms-flex-align: center;
        align-items: center;
    background-color: #FAFAFA;
    border-color: #B5B5B5;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    padding: 12px 10px;
}

.spreedly-dropin .spreedly-option .spreedly-option__label {
    color: #000000;
    font-size: 16px;
    margin-left: 20px;
    width: 100%;
}

.spreedly-dropin .spreedly-option .spreedly-option__logo {
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    -ms-flex-align: center;
        align-items: center;
}

.spreedly-dropin .spreedly-option .spreedly-option__logo-svg {
   width: 48px;
   height: 29px;
}

.spreedly-dropin .spreedly-heading {
    color: #000000;
    font-size: 16px;
    margin-bottom: 10px;
}

.spreedly-dropin .spreedly-large-button {
    background: #FAFAFA;
    border-radius: 4px;
    color: #000000;
    cursor: pointer;
    font-size: 14px;
    margin: 0 auto;
    padding: 18px;
    text-align: center;
    width: 100%;
    text-decoration: underline;
}

.spreedly-dropin .spreedly-sheet {
    background-color: white;
    border: 1px solid #B5B5B5;
    border-radius: 4px;
    margin: 0 auto;
    max-height: 700px;
    -webkit-transition: opacity 300ms, max-height 300ms ease, -webkit-transform 300ms;
    transition: opacity 300ms, max-height 300ms ease, -webkit-transform 300ms;
    -o-transition: transform 300ms, opacity 300ms, max-height 300ms ease;
    transition: transform 300ms, opacity 300ms, max-height 300ms ease;
    transition: transform 300ms, opacity 300ms, max-height 300ms ease, -webkit-transform 300ms;
    width: 100%;
    margin-bottom: 10px;
}

.spreedly-dropin .spreedly-sheet__header {
    -ms-flex-align: center;
        align-items: center;
    border-bottom: 1px solid #B5B5B5;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 12px 15px 0 12px;
    position: relative;
}

.spreedly-dropin .spreedly-sheet__header .spreedly-sheet__header-label {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 1;
        flex-grow: 1;
    padding-bottom: 12px;
}

.spreedly-dropin .spreedly-sheet__header .spreedly-sheet__logo--header {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    width: 50px;
}

.spreedly-dropin .spreedly-sheet__header .spreedly-sheet__label {
    font-size: 16px;
    margin-left: 20px;
}

.spreedly-dropin .spreedly-sheet__header .spreedly-option__logo-svg {
    width: 48px;
    height: 29px;
}

.spreedly-dropin .spreedly-sheet__content--form {
    padding: 10px 15px 10px 10px;
}

.spreedly-dropin .spreedly-sheet__header .spreedly-sheet__icons {
    padding-bottom: 10px;
}

.spreedly-dropin .spreedly-sheet__header .spreedly-sheet__card-icon {
    display: inline-block;
    padding-left: 5px;
}

/* ------------------------------------------------------------ *\
	Survey Questions
\* ------------------------------------------------------------ */

.survey-questions { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    overflow-x: scroll;
    /*scroll-snap-type: x mandatory;*/
    /*scrollbar-width: none;*/
    /*-ms-overflow-style: none;*/
}

.survey-questions::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
    }

.survey-questions .survey-question { -ms-flex: 0 0 auto; flex: 0 0 auto; scroll-snap-align: center; width: 200px; margin: 0px auto; }

.survey-questions .survey-question-text {font-weight: 500; margin-left:25px; margin-right: 25px;}

.survey-questions .survey-question:first-child { margin-left: 50px; }

.survey-questions::after {content:" "; width: 50px; -ms-flex: 0 0 auto; flex: 0 0 auto;}

/* ------------------------------------------------------------ *\
	Tab Open Header
\* ------------------------------------------------------------ */

.tab-open-header { position:-webkit-sticky; position:sticky; height:40px; padding: 22px 0 0; top:0px; z-index: 40; background-color: var(--tab-open-color); padding-bottom: 40px; -webkit-box-shadow: 3px 1px 10px 6px #cccccc75; box-shadow: 3px 1px 10px 6px #cccccc75; }

.tab-open-header .tab-open-header__content { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
        -ms-flex-pack: justify;
            justify-content: space-between;
        -ms-flex-align: center;
            align-items: center;
        font-weight: 500;
        color: black;
    }

.tab-open-header .tab-open-header__tab-open {  }

.tab-open-header .tab-open-header__view-tab { margin-right:11px; position: relative; }

/* ------------------------------------------------------------ *\
	Tab Receipt
\* ------------------------------------------------------------ */

.tab-receipt { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex: 1 1;
        flex: 1 1;
    -ms-flex-direction: column;
        flex-direction: column;
}

.tab-receipt .tab-receipt__head { padding: 22px 0 0; font-size: 12px; font-weight: 500; text-align: center; color:var(--header-text-color); background-color:var(--header-background-color); }

.tab-receipt .tab-receipt__head-inner { position: relative; margin-bottom: 22px; padding: 0 30px; }

.tab-receipt .tab-receipt__head-btn { position: absolute; left: 0; top: 0px; padding: 10px 10px 10px 6px; background: none; border: none; font-size: 17px; color: var(--header-btn-color); }

[dir="rtl"] .tab-receipt .tab-receipt__head-btn {left: initial; right: 0;}

.tab-receipt .tab-receipt__hr { border-top: 1px solid var(--border-color); padding-bottom: 25px; }

.tab-receipt .tab-receipt__title { font-family: var(--font-family-sans-serif-alt); font-size: 16px; font-weight: 500; text-transform: uppercase; }

.tab-receipt .tab-receipt__body {-ms-flex: 1 1;flex: 1 1}

.tab-receipt .tab-receipt__actions .btn + .btn { margin-top: 10px; }

.tab-receipt .tab-receipt__section { margin-bottom: 16px; }

.tab-receipt .tab-receipt__section--alt { margin-bottom: 30px; }

.tab-receipt .tab-receipt__section--modified { margin-bottom: 50px; }

.tab-receipt .tab-receipt__section-inner { padding: 0 25px; }

.tab-receipt .tab-receipt__section-actions { text-align: center; }

.tab-receipt .tab-receipt__section-actions .btn + .btn { margin-top: 10px; }

.tab-receipt .tab-receipt__section-actions--space { margin-top: 35px; margin-bottom: 35px }

.tab-receipt .tab-receipt__section-actions--flex { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
}

.tab-receipt .tab-receipt__section-actions--flex .btn { max-width: 50%; -ms-flex: 0 0 50%; flex: 0 0 50%; }

.tab-receipt .tab-receipt__section-actions--flex .btn + .btn { margin-top: 0; margin-left: 5px; }

[dir="rtl"] .tab-receipt .tab-receipt__section-actions--flex .btn + .btn { margin-left: 0px; margin-right: 5px;}

.tab-receipt .tab-receipt__section-text { margin-bottom: 17px; text-align: center; }

.tab-receipt .tab-receipt__section-title { margin-bottom: 9px; font-size: 14px; }

.tab-receipt .tab-receipt__foot { position: -webkit-sticky; position: sticky; bottom: 0; left: 0; z-index: 30; padding: 10px 0; background: var(--background-color); }

/* ------------------------------------------------------------ *\
	Table
\* ------------------------------------------------------------ */

.table { font-size: 12px; text-align: left; }

.table .table__edit-btn { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
        -ms-flex-pack: start;
            justify-content: flex-start;
        -ms-flex-align: center;
            align-items: center;
        background: none;
        border: none;
        font-size: 14px;
        text-transform: uppercase;
        color: var(--edit-basket-color);
        direction: ltr;
    }

.table .table__edit-btn span { margin-left: 7px; font-size: 15px; font-weight: 500; }

.table th { font-size: 12px; font-weight: 300; text-transform: uppercase; }

.table th.qty { text-align: right; padding-right: 7px; }

.table th.price { text-align: right; }

.table strong.block { display: block; }

.table td span { display: block; font-size: 10px; line-height: 1; }

.table th { padding: 6px 0; border-top: 1px solid; border-color:var(--border-color); color: black; }

.table td { vertical-align: top; padding: 10px 0; border-width: 1px 0; border-style: solid; border-color:var(--border-color); }

.table th:first-child,
.table td:first-child { padding-left: 15px; }

.table th:last-child,
.table td:last-child { padding-right: 15px; }

.table td.name { position: relative; width: 62%; padding-right: 20px; }

.table td.name strong { margin-bottom: 4px; }

.table td.is-editable { padding-left: 44px; }

.table .btn-edit { position: absolute; left: 17px; top: 17px; background: none; border: none; }

.table .btn-edit .icon { font-size: 14px; color: var(--edit-icon-color); }

.table td.qty { width: 82px; padding-right: 7px; text-align: right; }

.table td.price { text-align: right; }

[dir="rtl"] .table th:first-child,
[dir="rtl"] .table td:first-child { padding-left: 0px; padding-right: 15px;}

[dir="rtl"] .table th:last-child,
[dir="rtl"] .table td:last-child { padding-right: 0px; padding-left: 15px;  }

[dir="rtl"] .table th.qty { text-align: left; padding-right: 0px; padding-left: 7px; }

[dir="rtl"] .table td.qty { padding-right: 0px; padding-left: 7px; text-align: left; }

[dir="rtl"] .table th.price { text-align: left; }

[dir="rtl"] .table td.price { text-align: left; }

[dir="rtl"] .table td.name { padding-left: 20px; text-align: right;}

.react-toast-notifications__container {
    z-index: 10000 !important;
}

.toast-container {
    background-color: rgb(49, 49, 49);
    color: #FFFFFF;
    width: 200px;
    height: 30px;
    border-radius: 15px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
}

/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */

html {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s; /* To avoid a jumpy result */
}

body {
    min-height: 100%; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-direction: column;
        flex-direction: column;
}

#root { /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-positive: 1;
        flex-grow: 1;
}

.wrapper {
    -ms-flex-positive: 1;
        flex-grow: 1; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    -ms-flex-direction: column;
        flex-direction: column;
}

